.market-analysis-table {
    width: to-rem(966);
    overflow-x: auto;
}

.market-analysis-table__content {
    width: to-rem(3375);
    height: fit-content;
    flex-shrink: 0;
}

.market-analysis-table__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: to-rem(30);
    background-color: $gray-6d;
}

.market-analysis-table__header__text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 2;
    font-weight: $font-weight-black;
}
.market-analysis-table__header__empty {
    flex: 1;
}
