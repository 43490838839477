$character-size: 6.14rem;

.dialog {
    @include font-size(12px);

    position: absolute;
    bottom: 0;
    left: $character-size + 1rem;
    z-index: $focus-z-index;
    padding: 1rem 1.5rem;
    padding-right: 2.5rem;
    width: 65rem;
    min-height: to-rem(80);
    color: $black;
    font-weight: $font-weight-bold;
    line-height: 1.6;
    background: $white;
    border-radius: $border-radius;
    box-sizing: border-box;
}

.dialog--minimized {
    background: transparent;
    width: 1rem;
}

.dialog__delta {
    position: absolute;
    bottom: to-rem(22);
    left: to-rem(-18);
    z-index: -1;
}

.dialog__minimized-dots {
    position: absolute;
    top: 0;
    left: to-rem(155);
    z-index: -1;
    width: to-rem(53);
    cursor: pointer;
}

.dialog__close {
    position: absolute;
    top: to-rem(6);
    right: to-rem(6);
}
