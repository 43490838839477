.method {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.method__title {
    @include font-size(18px);

    color: $primary;
    font-weight: $font-weight-bold;

    @media(min-width: $screen-md) {
        @include font-size(24px);
    }
}

.method__description {
    @include font-size(12px);

    color: $white;
    line-height: to-rem(18);
    text-align: center;

    @media(min-width: $screen-md) {
        width: 70%;
        max-width: to-rem(640);
    }
}

.method__animation-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media(min-width: $screen-md) {
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-evenly;
        width: 100%;
    }
}

.method__animation {
    width: to-rem(166);
    margin: to-rem(20) 0 0;
}

.method__animation--one-guys {
    @media(min-width: $screen-lg) {
        width: to-rem(205);
    }
}


.method__animation--two-guys {
    @media(min-width: $screen-lg) {
        width: to-rem(225);
    }
}

.method__animation--three-guys {
    width: to-rem(208);

    @media(min-width: $screen-lg) {
        width: to-rem(296);
    }
}
