.concept-button {
    @extend %btn;
    @include font-size(14px);

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: to-rem(5) 0;
    padding: to-rem(15);
    color: $gray-d4;
    background: $gray-30;
    border-radius: $border-radius-sm;
    box-shadow: 0 2px 9px rgba($black, 0.45);
    text-align: left;

    &:hover, &:active {
        color: $gold;

        .concept-button__arrow {
            border-left: to-rem(7) solid $gold;
        }
    }

    &:focus {
        box-shadow: 0 2px 9px rgba($black, 0.45) !important;
    }

    @media(min-width: $screen-md) {
        @include font-size(16px);

        height: to-rem(68);
        margin: to-rem(7) 0;
        padding: to-rem(20);
        box-shadow: -4px 2px 9px rgba($black, 0.45);

        &:focus {
            box-shadow: -4px 2px 9px rgba($black, 0.45) !important;
        }

        &:hover, &:active {
            .concept-button__arrow {
                border-left: to-rem(9) solid $gold;
            }
        }
    }

    @media(min-width: $screen-lg) {
        height: to-rem(75);
        padding: to-rem(25);
    }

    @media(min-width: $screen-xl) {
        height: to-rem(90);
        padding: to-rem(30);
    }
}

.concept-button--sm {
    margin: 1rem 0;

    @media(min-width: $screen-md) {
        height: to-rem(60);
        margin: to-rem(20) 0;
    }
}

.concept-button--selected {
    color: $gold;

    .concept-button__arrow {
        border-left: to-rem(7) solid $gold;
    }

    @media(min-width: $screen-md) {
        .concept-button__arrow {
            border-left: to-rem(9) solid $gold;
        }
    }
}

.concept-button__arrow {
    width: 0;
    height: 0;
    border-top: to-rem(4) solid transparent;
    border-bottom: to-rem(4) solid transparent;
    border-left: to-rem(7) solid $gray-d4;

    @media(min-width: $screen-md) {
        border-top: to-rem(6) solid transparent;
        border-bottom: to-rem(6) solid transparent;
        border-left: to-rem(9) solid $gray-d4;
    }
}
