.bottom-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-right: 1rem;
    padding: 0.5rem 1rem;
    max-width: to-rem(500);
    max-height: to-rem(80);
    min-width: to-rem(360);
    background-color: $gray-30;
    border-radius: 15px;
    box-sizing: border-box;
}

    .bottom-box__title {
        @include font-size(16px);
        margin-bottom: to-rem(5);
        color: $white;
        font-weight: $font-weight-bold;
        text-transform: capitalize;
    }

    .bottom-box__products {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
    }

        .bottom-box__product {
            display: flex;
            margin-right: 1rem;
        }

        .bottom-box__product-img {
            margin-right: 0.5rem;
            max-width: to-rem(33);
            max-height: to-rem(30);
        }

        .bottom-box__product-text {
            @include font-size(21px);

            color: $white;
            font-weight: $font-weight-bold;
        }

.bottom-box--primary {
    border: 3px solid $primary;

    .bottom-box__title { color: $primary-extra-light; }
}

.bottom-box--secondary {
    border: 3px solid $secondary;

    .bottom-box__title { color: $secondary-extra-light; }
}
