.contact-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5rem 0;

    @media(min-width: $screen-md) {
        margin: 8rem 0 0;
    }
}

.contact-use__title {
    @include font-size(18px);

    margin-bottom: 1rem;
    color: $primary;
    font-weight: 700;

    @media(min-width: $screen-md) {
        @include font-size(24px);

        margin-bottom: to-rem(30);
        margin-left: to-rem(30);
    }
}

.contact-us__email-box {
    display: flex;
    align-items: center;
    position: relative;
    width: to-rem(305);
    height: to-rem(40);
    padding-right: to-rem(12);
    color: $white;
    background: $gray-30;
    border-radius: $border-radius-lg $border-radius-sm $border-radius-sm $border-radius-lg;
    box-sizing: border-box;
    box-shadow: 0 4px 24px rgba($black, 0.45);

    @media(min-width: $screen-lg) {
        @include font-size(16px);

        width: to-rem(466);
        height: to-rem(60);
        padding-right: to-rem(43);
    }
}

.contact-us__icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: to-rem(60);
    height: to-rem(60);
    background: linear-gradient(180deg, $purple-75 0%, $purple-81 100%);
    border-radius: 50%;

    @media(min-width: $screen-lg) {
        width: to-rem(80);
        height: to-rem(80);
    }
}

.contact-us__icon {
    width: to-rem(28);
    height: to-rem(22);

    @media(min-width: $screen-lg) {
        width: to-rem(38);
        height: to-rem(28);
    }
}

.contact-us__link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: to-rem(80);
    width: 100%;
    color: $white;
    font-weight: 500;
    text-align: left;

    &:hover {
        color: $primary;
    }

    @media(min-width: $screen-lg) {
        @include font-size(16px);
    }
}
