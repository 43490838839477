.investments {
}

    .investments__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: $overlay;
    }

    .investments__tabs {
        position: fixed;
        top: to-rem(140);
        left: 0;
        z-index: $focus-z-index;

        > * {
            margin-bottom: 1rem;
        }
    }

    .investments__content {
        position: fixed;
        top: to-rem(50);
        left: to-rem(350);
    }
