.fullscreen-btn {
    @extend %btn;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2rem;
    width: to-rem(28);
    background-color: transparent;
}

    .fullscreen-btn__img {
        width: to-rem(28);
        height: to-rem(28);
    }
