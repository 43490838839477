$darker-green-94: darken($green-94, 5%) !default;

.action-button {
    @extend %btn;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
    padding: 0.6rem;
    width: 100%;
    height: to-rem(36);
    background-color: $green-c4;
    border: 2px solid $black;
    border-radius: $border-radius;
    box-sizing: border-box;

    &:before {
        position: absolute;
        top: to-rem(3);
        left: to-rem(2);
        z-index: -1;
        width: 98%;
        height: to-rem(26);
        background-color: $darker-green-94;
        border-radius: $border-radius;
        content: '';
    }

    &:after {
        position: absolute;
        top: to-rem(4);
        right: to-rem(4);
        width: to-rem(13);
        height: to-rem(8);
        background-color: $white;
        border-radius: 50%;
        transform: rotate(32.07deg);

        content: '';
    }

    &:hover {
        transform: scale(1.05);
    }

    &:disabled {
        cursor: not-allowed;
    }
}

.action-button--negative {
    background-color: $red-f5;

    &:before {
        background-color: $red-dd;
    }

    &:after {
        background-color: $red-f5;
    }


    .action-button__text {
        @extend %box-title--red;
    }
}

.action-button--hidden {
    z-index: none;
    background-color: $gray-5c;
    cursor: not-allowed;

    &:before {
        background-color: $gray-41;
    }

    &:after {
        background-color: $gray-5c;
    }

    .action-button__text {
        @extend %box-title--gray;
        color: $gray-5c;
    }
}

.action-button__text {
    @extend %box-title--green;
    @include font-size(16px);

    width: 100%;
    color: $white;
    font-weight: $font-weight-bold;
    text-align: center;
    text-transform: uppercase;
}

.action-button--sm {
    padding: 1rem;

    .action-button__text {
        @include font-size(12px);
    }
}

.action-button--landing {
    max-width: to-rem(130);
    background-color: $primary-light;

    &::before {
        background-color: $primary;
    }

    &::after {
        background-color: $primary-light;
    }

    @media(min-width: $screen-md) {
        max-width: to-rem(180);
    }
}

.action-button--desktop {
    display: none;

    @media(min-width: $screen-md) { display: flex; }
}

.action-button--mobile {
    display: flex;

    @media(min-width: $screen-md) { display: none; }
}

    .action-button-text {
        @extend %box-title--purple;
        @include font-size(12px);

        @media(min-width: $screen-md) {
            @include font-size(16px);
        }
    }
