.plant-action-button {
    z-index: $focus-z-index;
    margin: auto;
    width: 19rem;
}

.plant-action-button__coin {
    margin-right: 0.5rem;
    width: to-rem(16);
    height: to-rem(16);
}

.plant-action-button__cost {
    @include font-size(12px);
}

.plant-action-button__separator {
    margin: 0 to-rem(11);
    width: to-rem(1);
    height: to-rem(12);
}

.plant-action-button__separator--positive {
    border-right: 2px solid $green-c4;
}

.plant-action-button__separator--negative {
    border-right: 2px solid $red-f5;
}
