.graphics {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.graphics__dynamic {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: to-rem(450);
}

.graphics__title {
    @extend %box-title;

    margin-bottom: 1rem;
}
