.market {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    padding: 0.3rem 0.3rem 0.3rem 1rem;
    width: 100%;
    height: to-rem(36);
    background: $gray-30;
    border-radius: $border-radius;
    box-sizing: border-box;
}

.market__name {
    @include font-size(12px);

    color: $gray-d8;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}

.market__status {
    @include font-size(12px);

    position: relative;
    margin-left: 1rem;
    width: to-rem(184);
    height: 100%;
    min-width: to-rem(184);
    background: $black;
    border-radius: $border-radius;
    cursor: pointer;
    overflow: hidden;
    will-change: transform;
}

.market__status--disabled {
    cursor: not-allowed;
}

.market__status-text {
    position: absolute;
    top: 50%;
    left: 50%;
    color: $white;
    font-weight: $font-weight-bold;
    transform: translate(-50%, -50%);
}

.market__status-bar {
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    transition: transform 0.5s;
    will-change: transform;
}

@each $position, $color in $markets {
    .market__status-bar--#{$position} {
        background-color: $color;
        box-shadow: inset 0 1px 0 3px map.get($markets-light, #{$position}-light);

        &:after {
            position: absolute;
            top: to-rem(3);
            right: to-rem(3);
            width: to-rem(13);
            height: to-rem(8);
            background-color: map.get($markets-light, #{$position}-light);
            border-radius: 50%;
            transform: rotate(20deg);

            content: '';
        }
    }
}
