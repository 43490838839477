.borrowed-capital {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
}

.borrowed-capital__header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: to-rem(30);
    font-weight: $font-weight-bold;
    border-radius: to-rem(10) to-rem(10) 0 0;
}

    .borrowed-capital__header--short {
        background-color: $purple;
    }

    .borrowed-capital__header--long {
        background-color: $green;
    }

.debts__row {
    display: flex;
    flex-direction: row;
}

.debt-row__container--long {
    .borrowed-capital__coin-header {
        background-color: $green;
    }
}
