.inventory-types {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.7rem;
    width: to-rem(282);
    height: to-rem(50);
    background: $gray-30;
    border-radius: $border-radius-sm;
    box-sizing: border-box;
}

.inventory-types__employee {
    display: flex;
    flex-direction: row;
    position: relative;
}

.inventory-types__employee-remove {
    position: absolute;
    top: to-rem(-4);
    left: to-rem(6);
    cursor: pointer;
}

.inventory-types__employee-icon {
    margin-right: 0.2rem;
    height: to-rem(30);
}

.inventory-types__product {
    height: to-rem(30);
}
