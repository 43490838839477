.kpi-analysis {}

    .kpi-analysis__table {
        display: flex;
        flex-direction: column;
        width: to-rem(1345);
        background-color: $gray-d4;
        border: to-rem(4) solid $white;
        border-radius: 0 to-rem(20) to-rem(20);
        box-sizing: border-box;
        overflow-x: auto;
    }

    .kpi-analysis__data {
        display: flex;
        flex-direction: column;
        padding-top: to-rem(20);
        padding-left: to-rem(34);
    }

    .kpi-analysis__box {
        margin-bottom: to-rem(20);
    }

    .kpi-analysis__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding-left: to-rem(415);
        width: to-rem(1270);
        height: to-rem(30);
        font-weight: $font-weight-bold;
        background-color: $gray-6d;
        border-radius: to-rem(10) to-rem(10) 0 0;
        box-sizing: border-box;
    }

        .kpi-analysis__header-cell {
            width: to-rem(50);
            text-align: center;
        }

    .kpi-analysis__body {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-top: to-rem(15);
        padding-bottom: to-rem(10);
        width: to-rem(1270);
        height: fit-content;
        background-color: $gray-c4;
        border-radius: 0 0 to-rem(10) to-rem(10);
    }

        .kpi-analysis__row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            padding-bottom: to-rem(7);
            font-weight: $font-weight-bold;
        }

        .kpi-analysis__text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-left: to-rem(10);
            width: to-rem(220);
        }

        .kpi-analysis__description-text {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding-left: to-rem(10);
            width: to-rem(180);
            height: to-rem(30);
        }

        .kpi-analysis__sublabel {
            font-size: to-rem(8);
        }

        .kpi-analysis__subdesription {
            line-height: to-rem(10);
        }

        .kpi-analysis__cells {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            width: to-rem(850);
        }

            .kpi-analysis__cell {
                width: to-rem(50);
                text-align: center;
            }
