.cash-balance {
    margin: 0 to-rem(10);
}

.cash-balance__header {
    margin-bottom: to-rem(12);
}

.cash-balance__title {
    position: relative;
    top: 0;
    margin: 0;
}

.cash-balance__assets {
    width: to-rem(424);
}
