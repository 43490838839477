.graphics-pages {
    display: flex;
    align-items: center;
}

.graphics-pages__arrow {
    width: to-rem(51);
    height: to-rem(50);
    cursor: pointer;
}

.graphics-pages__quantity {
    @include font-size(21px);

    display: flex;
    align-items: center;
    margin: 0 to-rem(31);
    color: $white;
    font-weight: $font-weight-bold;
}

.graphics-pages__total-pages {
    margin-left: to-rem(5);
    color: $gray-d4;
}
