.product-communication-point {
    position: relative;
    width: 0.8rem;
    height: 0.8rem;
    background: $gray-d8;
    border-radius: 50%;
    cursor: pointer;
}

.product-communication-point--active {
    background: $lightblue;
}

.product-communication-point--disabled {
    cursor: not-allowed;
    pointer-events: none;
}

.product-communication-point__reward-coins {
    position: absolute;
    top: -1.8rem;
    left: -2rem;
    z-index: 1;
}

.product-communication-point__reward-points {
    position: absolute;
    top: -0.3rem;
    left: -0.3rem;
}
