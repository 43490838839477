.employee-unit {
    position: relative;
    width: to-rem(54);
}

.employee-unit__icon {
    position: absolute;
    top: to-rem(-4);
    left: to-rem(-3);
    height: to-rem(26);
}

.employee-unit__popper {
    z-index: $help-z-index;
}

.employee-unit__box {
    @include font-size(12px);

    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 0.8rem 0 0.5rem;
    width: 100%;
    color: $yellow;
    font-weight: $font-weight-bold;
    text-align: right;
    background: $gray-30;
    border-radius: $border-radius-sm;
    box-sizing: border-box;
}
