.level-connector {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.level-connector__space {
    flex: 0.3;
}

.level-connector__container {
    display: flex;
    align-items: center;
    flex: 1;
}

.level-connector__container--right {
    justify-content: flex-end;
}

.level-connector__container--left {
    justify-content: flex-start;
    margin-left: to-rem(-2);
}

.level-connector__gradient {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    width: to-rem(25);
    height: to-rem(7);
}

.level-connector__gradient--insufficient {
    z-index: 2;
    background: linear-gradient($insufficient, $sufficient);
}

.level-connector__gradient--sufficient {
    background: linear-gradient($sufficient, $good);
}

.level-connector__gradient--good {
    background: linear-gradient($good, $very-good);
}

.level-connector__gradient--very-good {
    z-index: 2;
    background: linear-gradient($very-good, $excellent);
}

.level-connector__line {
    position: absolute;
    top: to-rem(-8);
    z-index: 2;
    margin: auto;
    height: to-rem(22);
    border-right: 1px solid $white;
}
