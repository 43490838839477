.employees-salaries {}

    .employees-salaries__box {
        width: to-rem(402);
        flex-shrink: 0;
    }

        .employees-salaries__container {
            width: to-rem(132);
            height: to-rem(80);
        }

    .employees-salaries__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .employees-salaries__image {
        height: to-rem(45);
    }
