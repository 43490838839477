$connector-width: 1.64rem;

.marketing-power-product {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 0.4rem;
    margin-left: $connector-width;
    padding: 0.2rem 0.6rem;
    width: 30rem;
    background: $black;
    border-radius: $border-radius-sm;
    box-sizing: border-box;
}

.marketing-power-product__point {
    width: 0.8rem;
    height: 0.8rem;
    background: $gray-d8;
    border-radius: 50%;
}

.marketing-power-product__point--active {
    background: $lightblue;
}

.marketing-power-product__connector {
    position: absolute;
    left: -$connector-width;
    width: $connector-width;
    height: 0.4rem;
    background: $black;
}
