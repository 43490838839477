.procurement {
    position: relative;
    margin-top: 1.2rem;
}

.procurement__cost {
    position: absolute;
    top: -3.1rem;
    right: 0;
}
