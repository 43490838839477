.competitors-table {
    @include font-size(12px);

    width: 100%;
    color: $white;
    font-weight: $font-weight-bold;

    tr:first-child th:first-child {
        width: to-rem(131);
        padding-left: 1rem;
        border: 0;
        border-top-left-radius: $border-radius-sm;
        text-align: left;
        box-sizing: border-box;
    }

    tr:first-child th:last-child {
        border-top-right-radius: $border-radius-sm;
    }

    tr:last-child td:first-child {
        border-bottom-left-radius: $border-radius-sm;
    }

    tr:last-child td:last-child {
        border-bottom-right-radius: $border-radius-sm;
    }
}

.competitors-table__head {
    height: to-rem(30);
    background: $gray-6d;
    border-radius: $border-radius-xs $border-radius-xs 0 0;

    th {
        border: 1px solid $gray-d4;
    }
}

.competitors-table__body {
    background: $gray-c4;
}

.competitors-table__cell {
    height: to-rem(38);
    padding: 1rem;
    text-align: center;
    border: 1px solid $gray-d4;
    box-sizing: border-box;

    .competitors-table__product-quarter {
        position: relative;
        bottom: auto;
        margin: 0 auto;
    }
}

@each $position, $color in $markets {
    .competitors-table__cell-market--#{$position} {
        background-color: map.get($markets-light, #{$position}-light);
        border: 0;
        text-align: left;
    }
}

.competitors-table__cell-product {
    text-align: left;
    background: $gray-9d;
}


@each $product, $color in $products {
    .competitors-table__cell-product--#{$product} {
        background-color: $color;
    }
}

.competitors-table__cell-product--h-basic {
    color: $gray-30;
}
