.round-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 2.1rem;
    height: 2.1rem;
    font-weight: $font-weight-bold;
    text-align: center;
    border: 0;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.05);
    }

    &:disabled {
        background: url('../../assets/icons/round-button-disabled.svg') no-repeat;
        background-size: cover;
        cursor: not-allowed;
        transform: none;

        .round-button__action {
            -webkit-text-stroke: 1px $gray-30;
        }
    }
}

// Variants
.round-button--primary {
    background: url('../../assets/icons/round-button.svg') no-repeat;
    background-size: cover;
}

.round-button--secondary {
    background: url('../../assets/icons/round-button-delete.svg') no-repeat;
    background-size: cover;

    .round-button__action {
        -webkit-text-stroke: 0.5px $red-9a;
    }
}

.round-button--very-small {
    width: 1rem;
    height: 1rem;

    .round-button__action {
        @include font-size(14px);

        transform: rotate(45deg);
    }
}

.round-button--small {
    width: 1.7rem;
    height: 1.7rem;
}

// Sizes
.round-button--sm {
    width: 1.7rem;
    height: 1.7rem;
}

.round-button--xs {
    width: to-rem(17);
    height: to-rem(17);

    .round-button__action {
        @include font-size(15px);
    }
}

.round-button--xxs {
    width: 1rem;
    height: 1rem;
}

.round-button__action {
    @include font-size(25px);

    color: $white;
    line-height: 1;
    text-transform: uppercase;
    -webkit-text-stroke: 1px $green-4a;
}
