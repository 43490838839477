.finances {
    display: flex;
    flex-direction: row;
    margin-left: to-rem(-23);
}

    .finances__tabs {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: to-rem(23);
        padding: 1rem 0;
        height: 9.2rem;
    }

.current-assets__box {
    min-width: to-rem(638);
    min-height: to-rem(382);
}
