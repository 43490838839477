.main-board {
    margin: 0 auto;
    padding: 0 0.5rem;
    width: 100%;
    height: 100%;
    min-width: to-rem(1440); // Tablet adaptation
    color: $white;
    background-color: $gray-30;
    box-sizing: border-box;

    @media(min-width: $screen-xl) {
        max-width: to-rem(1440);
        min-width: 0;
    }
}

    .main-board__header {
        display: flex;
        justify-content: space-between;
    }

        .main-board__header-right {
            flex: 1;
        }

    .main-board__container {
        padding: 0 1rem;
    }

        .main-board__tabs {
            display: flex;
            flex-direction: row;
            width: 100%;
        }

        .main-board__content {
            position: relative;
            height: calc(100vh - (#{$tabs-height} + #{$header-height} + #{$footer-height}));
            background-color: $gray-ed;
            border: 4px solid $black;
            border-radius: 0 0 $border-radius $border-radius;
            overflow: auto;
            box-sizing: border-box;
        }

        .main-board__content--reports, .main-board__content--decision-aids {
            padding: to-rem(23) to-rem(23) to-rem(11);
            height: calc(100vh - (#{$header-height} + #{$footer-height}));
            border-radius: to-rem(20);
            box-sizing: border-box;
        }

        .main-board__content--without-bottom-bar {
            height: calc(100vh - (#{$header-height} + #{to-rem(30)}));
            padding: to-rem(23) to-rem(23) to-rem(11);
            border-radius: to-rem(20);
        }

            .main-board__row {
                display: flex;
                flex-direction: row;
            }

        .main-board__content--big {
            padding: to-rem(28) to-rem(23) to-rem(18);
            height: calc(100vh - (#{$header-height} + #{$footer-height}));
            border-radius: $border-radius;
        }

        .main-board__overlay-content {
            position: relative;
            padding: to-rem(11) to-rem(23);
            min-height: 100%;
            box-sizing: border-box;
        }
