.plant {
    position: relative;
}

.plant--loading-3 {
    height: to-rem(563);
}

.plant--loading-2 {
    height: to-rem(380);
}

.plant--loading-1 {
    height: to-rem(184);
}

.plant__action-button {
    position: absolute;
    left: 1rem;
    margin: auto;
}

.plant__rent-cost {
    position: absolute;
    top: to-rem(-6);
    right: to-rem(10);
}

.plant__connector {
    position: absolute;
    bottom: to-rem(42);
    left: to-rem(-22);
    width: to-rem(22);
    height: to-rem(42);
    background: $gray-d4;
    border-top: 4px solid $white;
    border-bottom: 4px solid $white;
}

.plant__machine {
    margin-bottom: to-rem(31);
}

.plant__skeleton-machine {
    display: flex;
    align-items: flex-end;
    position: relative;
    margin-bottom: to-rem(28);
    padding-bottom: to-rem(17);
    height: to-rem(154);
    box-sizing: border-box;
}

.plant__skeleton-machine--unique {
    margin-bottom: 0;
}
