.points {
    @include font-size(10px);

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: to-rem(20);
    height: to-rem(20);
    font-weight: $font-weight-bold;
    text-align: center;
    border: 0;
}

.points--current {
    color: $insufficient;
    background: url('../../assets/icons/point-current.svg') no-repeat;
    background-size: cover;
}

.points--add {
    color: $gold-dark;
    background: url('../../assets/icons/point-add.png') no-repeat;
    background-size: cover;
}

.points--delete {
    color: $red-9a;
    background: url('../../assets/icons/point-delete.png') no-repeat;
    background-size: cover;
}

.points--sm {
    width: to-rem(30);
    height: to-rem(30);
}

@each $level, $color in $levels {
    .points--#{$level} {
        color: $color;
        background: url('../../assets/icons/point-#{$level}.png') no-repeat;
        background-size: cover;
    }
}

@each $level, $color in $levels {
    .points--xl {
        @include font-size(21px);

        width: to-rem(80);
        height: to-rem(80);

        &.points--#{$level} {
            color: $color;
            background: url('../../assets/icons/big-point-#{$level}.png') no-repeat;
            background-size: cover;
        }
    }
}
