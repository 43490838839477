.add-delete-employee {}

.add-delete-employee__icon {
    height: to-rem(30);
}

.add-delete-employee__x {
    @include font-size(10px);

    margin-left: 0.5rem;
    color: $white;
    font-weight: $font-weight-bold;
}

.add-delete-employee__amount {
    @include font-size(12px);

    margin-left: 0.2rem;
    color: $white;
    font-weight: $font-weight-bold;
}
