.strategic-evaluation {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 to-rem(30);
    box-sizing: border-box;
}

.strategic-evaluation__subtitle {
    margin-bottom: to-rem(20);
    text-transform: uppercase;
}
