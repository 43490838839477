.communications-policy {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.communications-policy__title {
    @include font-size(12px);

    margin-bottom: 0.5rem;
    color: $gray-d8;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}
