.machine {
    display: flex;
    flex-direction: column;
    position: relative;
    width: fit-content;
    z-index: 1;
}

.machine__box {
    position: relative;
    padding-top: to-rem(46);
    height: to-rem(134);
    box-sizing: border-box;
}

.machine__band {
    position: relative;
    width: to-rem(276);
    height: to-rem(70);
    border-radius: $border-radius $border-radius 0 0;
}

.machine__band--manual {
    height: to-rem(66);
    background-size: 200% 200%;
    background-image: repeating-linear-gradient(
        to right,
        $gray-c4,
        $gray-c4 to-rem(2),
        $gray-ed to-rem(2),
        $gray-ed to-rem(28)
    );

    &.producing {
        animation: slide 20s linear infinite;
    }
}

.machine__band--full-semi-automatic {
    background-size: 200% 200%;
    background-image: repeating-linear-gradient(
        to right,
        $gray-97,
        $gray-97 to-rem(2),
        $gray-b4 to-rem(2),
        $gray-b4 to-rem(20)
    );

    &.producing {
        animation: slide 10s linear infinite;
    }
}

.machine__band--full-automatic {
    background-size: 200% 200%;
    background-image: repeating-linear-gradient(
        to right,
        $gray-30,
        $gray-30 to-rem(2),
        $gray-6d to-rem(2),
        $gray-6d to-rem(20)
    );

    &.producing {
        animation: slide 2s linear infinite;
    }
}

.machine__workers {
    position: absolute;
    top: to-rem(-47);
    left: to-rem(72);
    z-index: -1;
}

.machine__assigned-product {
    position: absolute;
    top: to-rem(-4);
    z-index: 1;
}

.machine__quarter {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
    margin-left: to-rem(38);
}

.machine__quarter--full-automatic {
    position: absolute;
    top: to-rem(-12);
    left: to-rem(62);
}

.machine__status {
    position: absolute;
    top: to-rem(20);
    left: to-rem(110);
    z-index: 2;
    width: to-rem(91);
}

.machine__status-button {
    @extend %box-title--green;
    @include font-size(16px);

    color: $white;
    font-weight: $font-weight-bold;
    text-align: center;
    text-transform: uppercase;
}

.machine__select-button {
    @extend %box-title--green;
    @include font-size(16px);

    position: absolute;
    top: to-rem(65);
    left: to-rem(70);
    z-index: $focus-z-index;
    width: to-rem(180);
    color: $white;
    font-weight: $font-weight-bold;
    text-align: center;
    text-transform: uppercase;
}

.machine__sell-button {
    position: absolute;
    top: to-rem(65);
    left: 1rem;
}

.machine--empty {
    .machine__box {
        padding-top: 0;
    }
}
