$animation-diagonal-height: 64;

.concept-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: to-rem(320 - $animation-diagonal-height);
    position: relative;
    background: $gray-30;
    filter: drop-shadow(-7px 8px 16px $overlay);
    border-radius: $border-radius-lg;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        top: to-rem(-20);
        left: 0;
        background: $gray-30;
        border-radius: $border-radius-lg $border-radius-lg 0 0;
        transform: skewy(-4deg);
        transform-origin: top;
        height: to-rem($animation-diagonal-height);
    }

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        bottom: to-rem(-20);
        left: 0;
        background: $gray-30;
        border-radius: 0 0 $border-radius-lg $border-radius-lg;
        transform: skewy(4deg);
        transform-origin: top;
        height: to-rem($animation-diagonal-height);
        z-index: -1;
    }

    @media(min-width: $screen-md) {
        height: to-rem(461 - ($animation-diagonal-height * 2));
    }

    @media(min-width: $screen-lg) {
        height: to-rem(580 - ($animation-diagonal-height * 2));
    }
}

.concept-animation__banner {
    width: to-rem(220);

    @media(min-width: $screen-md) {
        width: 60%;
    }

    @media(min-width: $screen-xxl) {
        width: 50%;
    }
}

.concept-animation__banner--blackboard {
    width: to-rem(260);

    @media(min-width: $screen-md) {
        width: 75%;
    }

    @media(min-width: $screen-xxl) {
        width: 70%;
    }
}

.concept-animation__banner--machine {
    width: to-rem(240);

    @media(min-width: $screen-md) {
        width: 70%;
    }
}

.concept-animation__machine {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-right: to-rem(23);
    z-index: -1;
    transform: scale(0.9);

    .machine__box {
        height: auto;
    }

    .machine__band {
        box-shadow: 0 0 10px $black;
    }

    .machine-stand__continous-track {
        box-shadow: 0px 4px 5px $black;
    }

    @media(min-width: $screen-md) {
        transform: scale(1);
    }

    @media(min-width: $screen-lg) {
        margin-right: to-rem(28);
        transform: scale(1.2);
    }

    @media(min-width: $screen-xl) {
        margin-right: to-rem(38);
        transform: scale(1.4);
    }

    @media(min-width: $screen-xxl) {
        margin-right: to-rem(42);
        transform: scale(1.8);
    }
}
