.reports {}

    .reports__header {
        display: flex;
        justify-content: space-between;
    }

        .reports__header__right {
            flex: 1;
        }

    .reports__tabs {
        display: flex;
        flex-direction: row;
        flex: 1;
        height: to-rem(40);
    }


    .reports__table {
        width: to-rem(1320);
        background-color: $gray-d4;
        border: to-rem(4) solid $white;
        border-radius: 0 to-rem(20) to-rem(20);
        box-sizing: border-box;
    }

    .reports__table--income-statement, %reports__table--income-statement {

        .reports__table__cell {

            &:nth-of-type(1) {
                flex-basis: to-rem(350);
            }

            &:nth-of-type(2) {
                flex-basis: to-rem(20);
            }
        }
    }

    .reports__table--balance-sheet {

        .reports__table__cell {

            &:nth-of-type(1) {
                flex-basis: to-rem(210);
            }

            &:nth-of-type(2) {
                flex-basis: to-rem(200);
            }
        }
    }

    .reports__table--cash-flow {

        @extend %reports__table--income-statement;
    }

        .reports__table__header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0 to-rem(13) 0 to-rem(26);
            width: 100%;
            height: to-rem(44);
            font-size: to-rem(16);
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            background-color: $gray-6d;
            border-radius: 0 to-rem(20) 0 0;
            box-sizing: border-box;
        }

        .reports__table__cell {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
            flex-basis: to-rem(50);
        }

            .reports__table__cell__label {
                line-height: to-rem(19);
            }

            .reports__table__cell__text {
                font-size: to-rem(8);
                line-height: to-rem(8);
            }

        .reports__table__body {}

            .reports__table__body__row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 0 to-rem(13) 0 to-rem(26);
                height: to-rem(32);
                color: $gray-30;
                font-size: to-rem(12);
                font-weight: $font-weight-bold;
                background-color: $gray-d4;

                &:last-child {
                    border-radius: 0 0 to-rem(15) to-rem(15);
                }
            }

            .reports__table__body__row--lg {
                height: to-rem(36);
                font-size: to-rem(16);
            }

            .reports__table__body__row--xl {
                height: to-rem(40);
                font-size: to-rem(16);
            }

            .reports__table__body__row--sm {
                height: to-rem(36);
                font-size: to-rem(16);
                background-color: $gray-ed;
            }

            .reports__table__body__row--dark {
                background-color: $gray-c4;
            }

            .reports__table__body__row--light {
                background-color: $gray-ed;
            }

            .reports__table__body__row--bordered {
                border-bottom: to-rem(1) solid $gray-c4;
            }

