$cell-width: to-rem(187);

.market-position-header {
    @include font-size(12px);

    display: flex;
    align-items: center;
    padding-left: $cell-width;
    width: fit-content;
    height: to-rem(40);
    color: $white;
    font-weight: $font-weight-black;
    text-transform: uppercase;
    background: $gray-6d;
    border-radius: to-rem(16) to-rem(16) 0 0;
}

.market-position-header__team {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $cell-width;
}
