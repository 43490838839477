.equity-capital {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

    .equity-capital__box {
        width: to-rem(460);
        min-height: to-rem(225);
    }

    .equity-capital__reserves {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .equity-capital__reserves-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 1rem;
    }

    .equity-capital__current-reserves {
        @include font-size(12px);

        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 0.5rem;
        font-weight: $font-weight-bold;
    }

    .equity-capital__mu {
        @include font-size(10px);

        margin-left: 0.2rem;
        text-transform: uppercase;
    }

    .equity-capital__coin {
        width: to-rem(28);
        height: to-rem(28);
    }

    .equity-capital__confirm {
        width: 9rem;
    }
