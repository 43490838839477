.asset-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    width: to-rem(574);
    height: to-rem(48);
    background-color: $gray-c4;
    border-radius: $border-radius-sm;
}

.asset-container__label-container {
    width: to-rem(94);
    height: 100%;
    float: left;
    text-align: center;
    background-color: $gray-6d;
    border-radius: $border-radius-sm 0 0 $border-radius-sm;
}

    .asset-container__label {
        @include font-size(12px);

        margin: to-rem(15) to-rem(49) to-rem(15) to-rem(10);
        color: $white;
        font-weight: $font-weight-bold;
        text-align: initial;
    }

.asset-container--receivable {
    position: relative;
    height: to-rem(120);

    .asset-container__label {
        margin: to-rem(45) to-rem(15) to-rem(49) to-rem(10);
        line-height: to-rem(16);
    }
}

.asset-container__body-container {
    margin: to-rem(10) to-rem(366) to-rem(12) to-rem(10);
}

.asset-container--raw-materials, %asset-container--raw-materials {
    width: to-rem(180);
    height: to-rem(44);
    background-color: $gray-30;

    .asset-container__label-container {
        width: to-rem(80);
    }

    .asset-container__label {
        margin-top: to-rem(6);
        width: 100%;
        line-height: to-rem(16);
    }

    .asset-container__body-container {
        margin-left: to-rem(22);
        align-self: center;
    }
}

.asset-container--semi-finished-products {
    width: to-rem(210);
    height: to-rem(44);
    background-color: $gray-30;

    .asset-container__label-container {
        width: to-rem(110);
    }

    .asset-container__label {
        margin-top: to-rem(6);
        width: 100%;
        line-height: to-rem(16);
    }

    .asset-container__body-container {
        margin-left: to-rem(22);
        align-self: center;
    }
}

.asset-container--finished-products {
    width: to-rem(176);
    height: to-rem(44);
    background-color: $gray-30;

    .asset-container__label-container {
        width: to-rem(75);
    }

    .asset-container__label {
        margin-top: to-rem(6);
        width: 100%;
        line-height: to-rem(16);
    }

    .asset-container__body-container {
        margin-left: to-rem(22);
        align-self: center;
    }
}

.asset-container--fixed-assets {
    height: to-rem(63);

    .asset-container__label-container {
        display: flex;
        justify-content: flex-start;
        width: to-rem(192);
    }

    .asset-container__body-container {
        margin: to-rem(20) to-rem(267) to-rem(12) to-rem(10);
    }

    .asset-container__label {
        margin: initial;
        margin-left: to-rem(10);
        align-self: center;
    }
}

.asset-container--equity-capital {
    @extend %asset-container--raw-materials;

    display: flex;
    width: 100%;
    height: to-rem(63);

    .asset-container__label-container {
        display: flex;
        width: to-rem(105);
    }

    .asset-container__label {
        margin: 0 to-rem(10);
        align-self: center;
    }

    .asset-container__body-container {
        margin: 0 0.5rem;
    }
}

.asset-container--expenses {
    margin-bottom: to-rem(7);
    width: to-rem(250);
    height: fit-content;
    min-height: to-rem(44);

    .asset-container__label-container {
        display: flex;
        align-items: center;
        width: to-rem(120);
    }

    .asset-container__label {
        margin: to-rem(5);
        padding-left: 0.5rem;
    }

    .asset-container__body-container {
        display: flex;
        align-items: center;
        margin: 0;
        padding-left: to-rem(15);
    }
}

.asset-container--employees-salaries {
    margin-bottom: to-rem(10);
    width: to-rem(262);
    height: to-rem(80);
    border-radius: to-rem(10);

    .asset-container__label-container {
        @include font-size(12px);

        display: flex;
        align-items: center;
        padding-left: to-rem(10);
        width: to-rem(132);
        text-transform: uppercase;
    }

    .asset-container__label {
        margin: initial;
    }

    .asset-container__body-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: initial;
        width: to-rem(130);
        height: to-rem(80);
    }
}

.asset-container--rents, %asset-container--rents {
    width: to-rem(388);
    height: to-rem(346);

    .asset-container__label-container {
        @include font-size(12px);

        display: flex;
        align-items: center;
        justify-content: center;
        width: to-rem(124);
        height: 100%;
    }

    .asset-container__label {
        margin: to-rem(100) to-rem(55) 0 to-rem(20);
        text-transform: uppercase;
    }

    .asset-container__body-container {
        margin: initial;
        width: 100%;
        height: 100%;
    }
}

.asset-container--storage {
    @extend %asset-container--rents;
    height: to-rem(171);

    .asset-container__label {
        margin: 0 to-rem(40) 0 to-rem(20);
    }
}
