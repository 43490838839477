.active-markets {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: to-rem(386);
    height: to-rem(164);
    padding: to-rem(18) to-rem(30);
    box-sizing: border-box;
}

    .active-markets__item {
        display: flex;
        flex-basis: 48%;
        align-items: center;
        justify-content: space-between;
        margin-bottom: to-rem(8);
        padding: to-rem(5) to-rem(5) to-rem(5) to-rem(16);
        height: to-rem(36);
        background: $gray-30;
        border-radius: $border-radius;
        box-sizing: border-box;
    }

    .active-markets__item--full-width {
        flex-basis: 100%;
    }    

    .active-markets__name {
        @include font-size(12px);

        color: $gray-d8;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }

    .active-markets__indicator {
        width: to-rem(27);
        height: to-rem(27);
        border-radius: to-rem(13);
        position: relative;
        box-sizing: border-box;

        &:after {
            position: absolute;
            top: to-rem(3);
            right: to-rem(3);
            width: to-rem(12);
            height: to-rem(7);
            border-radius: 50%;
            transform: rotate(20deg);
            content: '';
        }
    }
    
    .active-markets__indicator--disabled {
        background-color: $gray-d4;
        box-shadow: inset 0 to-rem(1) 0 to-rem(3) $gray-ed;

        &:after {
            background-color: $gray-ed;
        }        
    }

    @each $position, $color in $markets {
        .active-markets__indicator--#{$position} {
            background-color: $color;
            box-shadow: inset 0 to-rem(1) 0 to-rem(3) map.get($markets-light, #{$position}-light);

            &:after {
                background-color: map.get($markets-light, #{$position}-light);
            }
        }
    }


