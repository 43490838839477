.participants-intro {
    display: flex;
    margin: to-rem(22) 0 to-rem(22);
}

.participants-intro__general-data {
    display: flex;
    flex-direction: column;
    width: to-rem(420);
    margin-right: to-rem(160);
}

.participants-intro__functions-text {
    @include font-size(12px);

    display: flex;
    align-items: center;
    height: to-rem(40);
    margin-top: to-rem(10);
    color: $gray-6d;
    font-weight: $font-weight-bold;
}

.participants-intro__names {
    display: flex;
    flex-direction: column;
    width: to-rem(190);
}
