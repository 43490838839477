$tooltip-position: -1.45rem;

.training-bonus-tooltip, %training-bonus-tooltip {
    display: flex;
    align-items: center;
    top: -1.3rem;
    padding: 0.1rem 0.2rem;
    background: $white;
    border: 2px solid $red-9a;
    border-radius: $border-radius;

    &:after,
    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0.4rem;
        left: calc(50% - 0.4rem);
    }

    &:after {
        top: 1.6rem;
        border-color: $white transparent transparent;
    }

    &:before {
        top: 1.7rem;
        border-color: $red-9a transparent transparent;
    }
}

.training-bonus {
    position: absolute;
    z-index: 5;
}

.training-bonus--insufficient {
    top: -0.6rem;
    left: calc(50% - 0.7rem);
}

.training-bonus--sufficient {
    @extend %training-bonus-tooltip;

    right: $tooltip-position;
}

.training-bonus--good {
    @extend %training-bonus-tooltip;

    left: $tooltip-position;
}

.training-bonus--very-good {
    @extend %training-bonus-tooltip;

    right: $tooltip-position;
}

.training-bonus--excellent {
    @extend %training-bonus-tooltip;
    top: -1.2rem;
    left: -1.6rem;
    padding: 0.2rem 0.6rem;
}

.training-bonus--superlative {
    @extend %training-bonus-tooltip;

    right: 1.8rem;
    padding: 0.2rem 0.6rem;
}

.training-bonus__stack-worker {
    position: relative;
    width: 1rem;
    height: 1.3rem;

    :first-child {
        position: absolute;
        top: 0;
        left: 0.1rem;
    }

    :last-child {
        position: absolute;
        top: 0;
        left: 0.5rem;
        // filter: drop-shadow(-2px 0px 4px rgba($black, 0.5));

    }
}

.training-bonus__stack-worker--4 {
    display: flex;
    position: relative;
    width: 1.5rem;
    height: 1.3rem;

    :nth-child(1) {
        position: absolute;
        top: -0.1rem;
        left: -0.2rem;
    }

    :nth-child(2) {
        position: absolute;
        top: -0.1rem;
        left: 0.2rem;
    }

    :nth-child(3) {
        position: absolute;
        top: -0.1rem;
        left: 0.6rem;
    }

    :nth-child(4) {
        position: absolute;
        top: -0.1rem;
        left: 1rem;
    }
}


    .training-bonus__worker {
        width: auto;
        height: to-rem(20);
    }

