.machines-maintenance {
    width: to-rem(390);
    height: to-rem(528);
}

    .machines-maintenance__box {
        width: to-rem(450);
    }

    .machines-maintenance__header {
        @include font-size(12px);

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: to-rem(34);
        color: $white;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        background-color: $gray-6d;
        border-radius: to-rem(10) to-rem(10) 0 0;
    }

        .machines-maintenance__header__machine {
            text-align: center;
            flex-basis: 50%;
        }

        .machines-maintenance__header__years {
            padding-right: to-rem(11);
            text-align: center;
            flex-basis: 25%;
            box-sizing: border-box;
        }

        .machines-maintenance__header__costs {
            padding-left: to-rem(10);
            text-align: center;
            flex-basis: 25%;
            box-sizing: border-box;
        }

    .machines-maintenance__list {
        height: to-rem(467);
        background-color: $gray-c4;
    }

    .machines-maintenance__footer {
        @include font-size(12px);

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 to-rem(18);
        width: 100%;
        height: to-rem(34);
        color: $white;
        font-weight: $font-weight-bold;
        background-color: $gray-30;
        border-radius: 0 0 to-rem(10) to-rem(10);
        box-sizing: border-box;
    }

    .machines-maintenance__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-right: to-rem(12);
        padding-left: to-rem(16);
        height: to-rem(65);
        border-bottom: to-rem(2) solid $gray-d4;

        &:nth-child(7n) {
            border-bottom: 0;
        }
    }

        .machines-maintenance__row__img {
            display: flex;
            align-items: center;
            padding-top: to-rem(4);
        }

        .machines-maintenance__row__years {
            display: flex;
            align-items: center;
            padding-left: to-rem(5);
        }

        .machines-maintenance__row__costs {
            display: flex;
            align-items: center;
            padding-left: to-rem(5);
        }

        .machines-maintenance__img {
            width: to-rem(138);
            height: to-rem(44);

            .machine__box {
                padding-top: 0;
                height: to-rem(67);
                border-radius: $border-radius-sm $border-radius-sm 0 0;
            }

            .machine__band {
                width: to-rem(138);
                height: to-rem(35);
                border-radius: $border-radius-sm $border-radius-sm 0 0;
            }

            .machine__band--manual {
                height: to-rem(33);
                background-image: repeating-linear-gradient(
                    to right,
                    $gray-c4,
                    $gray-c4 to-rem(1),
                    $gray-ed to-rem(1),
                    $gray-ed to-rem(14)
                );
            }

            .machine__band--full-semi-automatic {
                background-image: repeating-linear-gradient(
                    to right,
                    $gray-97,
                    $gray-97 to-rem(1),
                    $gray-b4 to-rem(1),
                    $gray-b4 to-rem(10)
                );
            }

            .machine__band--full-automatic {
                background-image: repeating-linear-gradient(
                    to right,
                    $gray-30,
                    $gray-30 to-rem(1),
                    $gray-6d to-rem(1),
                    $gray-6d to-rem(10)
                );
            }

            .machine__assigned-product {
                top: to-rem(-2);
            }

            .machine__quarter {
                margin-left: to-rem(19);
            }

            .machine__quarter--full-automatic {
                top: to-rem(-6);
                left: to-rem(31);
            }

            .machine-stand__table-legs {
                bottom: to-rem(-10);
                width: to-rem(113);
            }

            .machine-stand__table {
                height: to-rem(4);
            }

            .machine-stand__legs {
                margin: 0 0.5rem;
            }

            .machine-stand__leg {
                width: to-rem(4);
                height: to-rem(7);
                border-radius: 0 0 to-rem(1) to-rem(1);
            }

            .machine-stand__continous-track {
                bottom: to-rem(-6);
                padding: 0 0.5rem 0 to-rem(32);
                width: to-rem(138);
                height: to-rem(10);
                border-radius: 0 $border-radius-sm $border-radius-sm 0;
            }

            .assigned-product {
                width: to-rem(25);
                height: to-rem(46);
                border-radius: to-rem(2);
            }

            .assigned-product__box {
                margin: to-rem(2) to-rem(2) 0;
                padding-top: 0.25rem;
                height: to-rem(29);
                border-radius: to-rem(2);
            }

            .assigned-product__icon {
                max-width: to-rem(15);
            }

            .assigned-product__cost {
                bottom: to-rem(-1);
                left: to-rem(-2);
            }

            .assigned-product__foot {
                height: to-rem(12);
                border-radius: 0 0 to-rem(2.5) to-rem(2.5);
            }

            .machine-stand__wheel {
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 50%;

                &:after {
                    padding-top: to-rem(1);
                    padding-left: to-rem(1);
                    width: to-rem(2);
                    height: to-rem(2);
                    border-radius: 50%;
                    content: '';
                }
            }

            .machine-quarter {
                width: to-rem(30);
                height: to-rem(31);
            }

            .machine-quarter--full-automatic {
                padding: to-rem(6) to-rem(8) to-rem(13);
                width: to-rem(55);
                height: to-rem(47);
            }

            .machine-quarter__full-automatic {
                width: to-rem(39);
                height: to-rem(28);
                background-image: repeating-linear-gradient(
                    to right,
                    $gray-97,
                    $gray-97 to-rem(1),
                    $gray-b4 to-rem(1),
                    $gray-b4 to-rem(10)
                );

                border: to-rem(2) solid $white;
            }

            .machine-quarter__arms {
                top: to-rem(-1);
                height: to-rem(14);
            }

            .machine-quarter__product {
                top: 0.4rem;
            }

        }

        .machines-maintenance__years {
            margin: 0 to-rem(5);
        }

