.close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: to-rem(36);
    height: to-rem(36);
    background-color: $primary;
    border: 2px solid $primary-light;
    border-radius: 50%;
    box-shadow: 0 0 0 4px $black;
    cursor: pointer;

    &:after {
        position: absolute;
        top: 0.3rem;
        right: 0.1rem;
        width: to-rem(7);
        height: to-rem(5);
        background-color: $primary-light;
        border-radius: 50%;
        transform: rotate(37.75deg);
        content: '';
    }
}

.close-button__icon {
    width: auto;
    height: to-rem(14);
}
