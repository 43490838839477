.loans {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

    .loans__button {
        position:absolute;
        bottom: to-rem(0);
        right: to-rem(0);
    }

    .loans__box {
        display: flex;
        margin-top: to-rem(100);
        margin-left: to-rem(33);
        width: to-rem(204);
        height: to-rem(376);
    }
