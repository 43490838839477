.concepts {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    @media(min-width: $screen-md) {
        flex-direction: row;
    }
}

.concepts__title {
    @include font-size(18px);

    color: $primary;
    font-weight: 700;
    text-align: center;

    @media(min-width: $screen-md) {

        @include font-size(24px);

        margin-left: to-rem(30);
        text-align: left;
    }
}

.concepts__selection {
    width: 100%;

    @media(min-width: $screen-md) {
        width: 48%;
    }
}

.concept__animation {
    width: 100%;

    @media(min-width: $screen-md) {
        width: 52%;
    }
}

.concepts__list {
    display: flex;
    flex-direction: column;
    margin: to-rem(20) 0 to-rem(45);

    @media(min-width: $screen-md) {
        margin: to-rem(20) 0 0;
    }
}
