.menu-list {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: $focus-z-index;
    padding: 1rem;
    width: to-rem(290);
    color: $black;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    background-color: $gray-ed;
    border: 3px solid $white;
    border-radius: $border-radius;
    box-shadow: 0 4px 24px 0 $black;
}

    .menu-list__title {
        @include font-size(21px);
        padding-left: 1rem;
    }

    .menu-list__items {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }

        .menu-list__item {
            display: flex;
            flex-direction: row;
            padding: 0.5rem 0;
            padding-left: 1rem;
            border-top: 1px solid $white;
            border-bottom: 1px solid $gray-d4;
            box-sizing: border-box;

            &:first-child { border-top: 0; }
            &:last-child { border-bottom: 0; }

            &:hover {
                .menu-list__icon { background-color: $primary; }
                .menu-list__link { color: $primary; }
            }
        }

        .menu-list__link {
            @include font-size(16px);
            font-weight: $font-weight-bold;
            text-transform: uppercase;
        }

        .menu-list__link--bullet {
            display: list-item;
            padding-left: 0.5rem;
            list-style-type: disc;
            list-style-position: inside;
        }

            .menu-list__icon {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0.7rem;
                width: to-rem(20);
                height: to-rem(20);
                min-width: to-rem(20);
                background-color: $gray-30;
                border-radius: 50%;
            }

            .menu-list__icon-img {
                width: to-rem(12);
                height: auto;
            }
