.markets-table {
    @include font-size(12px);

    width: 100%;
    margin-top: to-rem(20);
    font-weight: $font-weight-black;

    tr:first-child th:first-child {
        border-top-left-radius: $border-radius-sm;
    }

    tr:first-child th:last-child {
        border-top-right-radius: $border-radius-sm;
    }

    tr:last-child td:first-child {
        border-bottom-left-radius: $border-radius-sm;
    }

    tr:last-child td:last-child {
        border-bottom-right-radius: $border-radius-sm;
    }

    tr {
        height: to-rem(40);

        td:first-child {
            width: to-rem(452);
            max-width: to-rem(452);
        }
    }

    td {
        text-align: center;
        width: to-rem(200);
        max-width: to-rem(200);
    }
}


.markets-table__head {
    text-transform: uppercase;

    th {
        width: to-rem(200);
        max-width: to-rem(200);
    }

    :nth-child(1) {
        background: $gray-ed;
        color: $gray-6d;
    }

    :nth-child(2) {
        background: $gray-8d;
    }

    :nth-child(3) {
        background: $gray-6d;
    }

    :nth-child(4) {
        background: $gray-5c;
    }

    :nth-child(5) {
        background: $gray-30;
    }
}


@each $position, $color in $markets {
    .markets-table__cell--#{$position} {
        background-color: $color;
    }
    .markets-table__first-cell--#{$position} {
        background-color: map.get($markets-light, #{$position}-light);
    }
}
