@mixin dots-styling($width) {
    .loading-dots {
        position: relative;
        width: $width + 0px; // Tiene que ser 0px o no genera bien el dot
        height: calc($width / 3) - 10px;
    }

    .loading-dots__dot {
        position: absolute;
        width: calc($width / 3) - 10px;
        height: calc($width / 3) - 10px;
        border-radius: 50%;
        will-change: transform;
        animation: grow 1s ease-in-out infinite alternate;
    }

    .dot1 {
        left: 0;
        background-color: $gray-c4;
        transform-origin: 100% 50%;
    }

    .dot2 {
        left: 50%;
        background-color: $gray-97;
        transform: translateX(-50%) scale(1);
        animation-delay: 0.33s;
    }

    .dot3 {
        right: 0;
        background-color: $gray-5c;
        animation-delay: 0.66s;
    }

    @keyframes grow {
        to {
            transform: translateX(-50%) scale(0);
        }
    }
}

@include dots-styling($width: 60);

@media (min-width: $screen-md) {
    @include dots-styling($width: 70)
}
