.product-changeover {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $overlay;
    z-index: $modal-z-index;
}

.product-changeover__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: to-rem(740);
}

.product-changeover__machine {
    @extend %box-title;
    @include font-size(21px);

    display: flex;
    flex-direction: column;
    align-items: center;
}

.product-changeover__product-types {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
