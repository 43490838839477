.current-acquicition-capacity {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: to-rem(386);
    height: to-rem(68);
    padding: 0 to-rem(20);
    box-sizing: border-box;
}

.current-acquicition-capacity__product {
    display: flex;
    align-items: center;
}

.current-acquicition-capacity__product-image {
    height: to-rem(22);
}

.current-acquicition-capacity__product-capacity {
    @include font-size(21px);

    margin-left: to-rem(7);
    color: $gray-6d;
    font-weight: $font-weight-bold;
}
