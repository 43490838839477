$darker-green-94: darken($green-94, 5%) !default;
$team-dot-size: 14;


.order-card-list {
    display: flex;
    flex-wrap: wrap;
}

.order-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0.8rem;
    padding: 0.5rem 1rem;
    width: to-rem(210);
    height: to-rem(314);
    background-color: $market-first-light;
    border: 3px solid $white;
    border-radius: 10px;
    box-sizing: border-box;
}

    .order-card__text {
        color: $market-first;
        font-weight: $font-weight-bold;
        line-height: 1;
        text-transform: uppercase;
    }

    .order-card__title, %order-card-title {
        @include font-size(16px);

        position: relative;
        margin: 0.5rem 0 0.7rem;
        padding: 0.5rem;
        width: 86%;
        color: $white;
        font-weight: $font-weight-bold;
        text-align: center;
        text-transform: uppercase;
        background-color: $market-first;
        border-radius: 5px;
        box-shadow: inset 0 3px 3px rgba($black, 0.25);

        &:before {
            position: absolute;
            top: 1rem;
            left: -0.5rem;
            width: to-rem(14);
            height: to-rem(14);
            background-color: $market-first;
            border-radius: 50%;
            box-shadow: inset 1px 0 1px 0 rgba($black, 0.25);
            content: '';
        }

        &:after {
            position: absolute;
            top: 1rem;
            right: -0.5rem;
            width: to-rem(14);
            height: to-rem(14);
            background-color: $market-first;
            border-radius: 50%;
            box-shadow: inset -1px 0 1px 0 rgba($black, 0.25);
            content: '';
        }
    }

    .order-card__product {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: to-rem(80);
        box-sizing: border-box;
    }

        .order-card__product-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            flex: 1;
            position: relative;
            width: to-rem(90);
            height: to-rem(67);
            min-width: to-rem(90);
            background-color: $gray-30;
            border: to-rem(2) solid $black;
            border-radius: to-rem(10) to-rem(10) 0 0;
            box-sizing: border-box;
        }

            .order-card__product-img {
                width: auto;
                height: to-rem(32);
            }

            .order-card__product-amount {
                @include font-size(16px);

                margin-left: to-rem(7);
                color: $white;
                font-weight: $font-weight-bold;
            }

            .order-card__product-quarter {
                @include font-size(10px);

                display: flex;
                align-items: center;
                position: absolute;
                bottom: to-rem(-15.4);
                padding-right: to-rem(7);
                width: to-rem(95);
                height: to-rem(18);
                color: $gray-d8;
                font-weight: $font-weight-bold;
                background-color: $market-first;
                border-radius: to-rem(10);
            }

                .order-card__product-quarter-amount {
                    height: 100%;
                    margin-right: to-rem(2.8);
                    color: $white;
                }

                .order-card__product-quarter-clock {
                    margin-right: to-rem(2.8);
                    width: to-rem(23);
                    height: to-rem(22);
                }

        .order-card__product-description {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;
            flex: 2;
            text-align: right;
        }

            .order-card__product-name {
                @extend %box-title;
                @extend %box-title--sm;

                line-height: to-rem(24);
                text-align: right;
            }

            .order-card__product-quality {
                @include font-size(10px);

                display: flex;
                flex-direction: column;
            }

            .order-card__quality-label {
                margin-top: to-rem(1.4);
                padding: to-rem(2.8) to-rem(7);
                color: $white;
                font-weight: $font-weight-bold;
                border-radius: to-rem(2);
                text-align: center;
            }

            @each $level, $color in $levels {
                .order-card__quality-label--#{$level} {
                    background: $color;
                }
            }

    .order-card__money {
        margin: 0.9rem 0;
        padding: 1rem 0.7rem;
        width: 100%;
        background-color: $market-first;
        border-radius: 5px;
        box-sizing: border-box;
    }

        .order-card__money-label {
            @include font-size(12px);
            color: $white;
            font-weight: $font-weight-bold;
        }

// Order Card variants by market
@each $position, $color in $markets {
    .order-card--#{$position} {
        background-color: map.get($markets-light, #{$position}-light);
    }

    .order-card__title--#{$position} {
        background-color: $color;

        &:before, &:after {
            background-color: $color;
        }
    }

    .order-card__text--#{$position} {
        color: $color;
    }

    .order-card__product-quarter--#{$position} {
        background-color: $color;
    }

    .order-card__money--#{$position} {
        background-color: $color;
    }
}

.order-card--taken {
    background-color: $gray-5c;
    cursor: not-allowed;

    .order-card__text { color: $gray-41; }

    .order-card__money {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.5rem 0 0.8rem;
        padding: 1rem 0.3rem;
        width: 100%;
        background-color: $gray-41;
        border-radius: 5px;
        box-sizing: border-box;

    }

    .order-card__team {
        @include font-size(16px);

        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.5rem 0 1rem;
        padding: 1rem 0.7rem;
        width: 100%;
        height: to-rem(36);
        color: $white;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        background: url('../../assets/icons/team-label.svg') no-repeat;
        background-size: contain;
        box-sizing: border-box;
    }

    .order-card__title {
        color: $gray-5c;
        background-color: $gray-41;

        &::before, &::after {
            background-color: $gray-41;
        }
    }

    .order-card__product-name, .order-card__product-amount {
        color: $gray-5c;
    }

    .order-card__product-quarter, .order-card__product-quarter-amount {
        color: $gray-5c;
        background-color: $gray-41;
    }

    .order-card__quality-label {
        color: $gray-30;
        background-color: $gray-41;
    }

    .order-card__product-img, .order-card__product-quarter-clock {
        filter: grayscale(1);
        opacity: 0.3;
    }
}

.order-card--sm {
    padding: to-rem(6) to-rem(8);
    width: to-rem(147);
    height: to-rem(219);
    font-size: 0;
    border: to-rem(1.5) solid $white;
    border-radius: to-rem(5);

    .order-card__text {
        @include font-size(8px);
    }

    .order-card__title {
        @include font-size(10px);

        margin: to-rem(5) to-rem(5) to-rem(8);
        padding: to-rem(3.5);
        padding-top: to-rem(4);
        border-radius: to-rem(2.5);

        &:before {
            top: to-rem(7);
            left: to-rem(-3.5);
            width: to-rem(7);
            height: to-rem(7);
        }

        &:after {
            top: to-rem(7);
            right: to-rem(-3.5);
            width: to-rem(7);
            height: to-rem(7);
        }
    }

    .order-card__quality-label {
        @include font-size(8px);

        padding: to-rem(2.4) to-rem(3.5);
        border-radius: to-rem(1);
    }

    .order-card__money {
        margin: to-rem(12) 0 to-rem(8);
        padding: to-rem(10) to-rem(5);
        border-radius: to-rem(2.5);
    }

    .order-card__money-label {
        @include font-size(9px)
    }

    // Money

    .money-unit--default {
        @include font-size(8px);

        padding: to-rem(0) to-rem(8) to-rem(1.5) to-rem(14);
        width: to-rem(50);
        border-radius: to-rem(7);
    }

    .money-unit__icon {
        width: to-rem(18);
        height: to-rem(18);
    }

    .money-unit__amount {
        @include font-size(8px);

        margin-right: to-rem(2.4);
    }

    .money-unit__mu {
        @include font-size(7px);
    }

    .action-button {
        align-items: initial;
        padding: to-rem(5.5);
        height: to-rem(25);
        border: to-rem(1) solid $black;

        &:before {
            top: to-rem(1.5);
            left: to-rem(1);
            height: to-rem(20);
            border-radius: to-rem(10);
        }

        &:after {
            top: to-rem(2);
            right: to-rem(2);
            width: to-rem(6.5);
            height: to-rem(4);
        }
    }

    .order-card__product {
        height: to-rem(50);
    }

    .order-card__product-info {
        width: to-rem(70);
        height: to-rem(45);
        min-width: to-rem(65);
        border-radius: to-rem(8) to-rem(8) 0 0;
    }

    .order-card__product-img {
        height: to-rem(21);
    }

    .order-card__product-amount {
        margin-left: to-rem(3.5);
        font-size: to-rem(11);
    }

    .order-card__product-description {
        height: to-rem(54);
    }

    .order-card__product-quarter {
        @include font-size(8px);

        bottom: to-rem(-12);
        padding-right: to-rem(3);
        width: to-rem(72);
        height: to-rem(14);
        border-radius: to-rem(5);
    }

    .order-card__product-quarter-clock {
        margin-right: to-rem(4);
        width: to-rem(17);
        height: to-rem(17);
    }

    .order-card__product-quarter-amount {
        @include font-size(9px);

        margin-right: to-rem(1.4);
    }

    .order-card__product-name {
        @extend %box-title--xxs;
        @include font-size(10px);

        line-height: to-rem(12);
        text-transform: none;
    }

    .order-card__product-quality {
        font-size: 0;
    }

    .action-button__text {
        @include font-size(10px);
    }

    .order-card__team {
        @include font-size(10px);

        margin: to-rem(3.5) 0 to-rem(7);
        padding: to-rem(7) to-rem(5);
        height: to-rem(18);
    }
}

.order-card--skeleton {
    background: $gray-c4;
    border: 0;
}
