.business-plan-and-strategy {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 to-rem(30);
    box-sizing: border-box;
}

.business-plan-and-strategy__subtitle {
    margin-bottom: to-rem(20);
    text-transform: uppercase;
}

.business-plan-and-strategy__text {
    @include font-size(12px);

    color: $gray-6d;
    font-weight: $font-weight-bold;
}

// Competition strategy
.business-plan-and-strategy__competition-table {
    width: 100%;
    margin-bottom: to-rem(20);
    font-weight: $font-weight-black;

    tr:first-child th:first-child {
        border-top-left-radius: $border-radius-sm;
    }
    
    tr:first-child th:last-child {
        border-top-right-radius: $border-radius-sm;
    }

    tr:last-child td:first-child {
        border-bottom-left-radius: $border-radius-sm;
    }
    
    tr:last-child td:last-child {
        border-bottom-right-radius: $border-radius-sm;
    }
}

.business-plan-and-strategy__competition-table-head {
    @include font-size(12px);

    height: to-rem(40);
    background: $gray-6d;
    border-radius: $border-radius-xs $border-radius-xs 0 0;
    
    th {
        border: 0;
    }
}

.business-plan-and-strategy__competition-table-body {
    @include font-size(12px);

    background: $gray-c4;
    color: $gray-6d;
}

.business-plan-and-strategy__competition-cell {
    padding: 1rem;
    text-align: center;
}

.business-plan-and-strategy__competition-cell--first {
    color: $gray-30;
}
