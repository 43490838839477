//Small margins
.mb-sm {
    margin-bottom: 0.5rem;
}


//Positions
.relative {
    position: relative;
}

.absolute {
    position: absolute;
}


// Colors

.gray {
    color: $gray-5c;
}

.white {
    color: $white;
}

// Circle img
.circle-img, %circle-img {
    border-radius: 50%;
    object-fit: cover;
}

.bordered-img, %bordered-img {
    border-radius: 0 20px;
}

.bordered-img--dark, %bordered-img--dark {
    border-radius: 0 20px;
    filter: brightness(70%);
}


// Heights & Widths
.h-100 {
    height: 100% !important;
}

.h-screen-100 {
    height: 100vh !important;
}


.w-100 {
    width: 100% !important;
}


//File input
.file-input {
    display: none;
}

//Screen dedicated
.only-mobile {
    display: block;

    @media(min-width: $screen-md) {
        display: none;
    }
}

.only-md {
    display: none !important;

    @media(min-width: $screen-md) {
        display: block !important;
    }
}

.only-lg {
    display: none;

    @media(min-width: $screen-lg) {
        display: block;
    }
}

.only-xl {
    display: none;

    @media(min-width: $screen-xl) {
        display: block;
    }
}

//Float
.float-right {
    float: right;
}

.float-left {
    float: left;
}
