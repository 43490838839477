.decision-aids {}

    .decision-aids__table {
        display: flex;
        flex-direction: column;
        width: to-rem(1320);
        background-color: $gray-d4;
        border: to-rem(4) solid $white;
        border-radius: 0 to-rem(20) to-rem(20);
        box-sizing: border-box;
        overflow-x: scroll;
    }

    .decision-aids__table--production-plan {
        position: relative;
        height: to-rem(630);

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .decision-aids__table--market-analysis {
        background: $gray-ed;
        overflow-x: visible;
    }

    .decision-aids__data {
        display: flex;
        flex-direction: row;
    }

    .decision-aids__labels {
        min-width: to-rem(160);
        background: $gray-ed;
    }

    .decision-aids__labels-header-empty {
        height: to-rem(30);
        background-color: $gray-6d;
    }

    .decision-aids__labels-header-empty-light {
        height: to-rem(40);
        background: $gray-d4;
    }

    .decision-aids__labels-header-empty-dark {
        height: to-rem(40);
        background-color: $gray-c4;
    }

    .decision-aids__label {
        display: flex;
        align-items: center;
        height: to-rem(34);
        padding-left: to-rem(30);
        font-weight: $font-weight-bold;
    }

    .decision-aids__label--light {
        background: $gray-d4;
    }

    .decision-aids__label--dark {
        background: $gray-c4;
        border-bottom: 1px solid $white;
    }

    .decision-aids__tabs {
        display: flex;
        flex-direction: row;
        flex: 1;
        height: to-rem(40);
    }

    .decision-aids__years {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: sticky;
        top: 0;
        z-index: 1;
        width: to-rem(3675);
        height: to-rem(30);
        font-size: to-rem(12);
        font-weight: $font-weight-black;
        text-transform: uppercase;
        background-color: $gray-6d;
        border-radius: 0 to-rem(20) 0 0;
        box-sizing: border-box;
        flex-shrink: 0;
    }

        .decision-aids__years-label {
            position: sticky;
            left: 0;
            text-align: center;
            background-color: $gray-6d;
            flex-basis: 8.99%;
            flex-shrink: 0;
            box-sizing: border-box;
        }

        .decision-aids__years-label--production-plan {
            padding-left: to-rem(60);
            width: to-rem(3674);
            font-size: to-rem(12);
            font-weight: $font-weight-black;
            flex-basis: 9.5%;
        }

            .decision-aids__year {
                margin-left: to-rem(4);
                text-align: center;
                flex-basis: 9.1%;
            }

    .decision-aids__product-header {
        display: flex;
        flex-direction: row;
        height: to-rem(40);
        background-color: $gray-c4;
    }

    .decision-aids__product-header--production-plan {
        width: to-rem(3675);
        font-size: to-rem(12);
        font-weight: $font-weight-black;
    }

        .decision-aids__product-title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex: 1;
            font-size: to-rem(12);
            font-weight: $font-weight-black;
            text-transform: uppercase;
            box-sizing: border-box;
        }

        .decision-aids__product-title--production-plan {
            position: sticky;
            left: 0;
            min-width: to-rem(350);
            padding-left: to-rem(46);
            border-right: 1px solid $gray-ed;
            background: $gray-c4;
        }

        .decision-aids__product-header__products {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            flex: 2;
            height: 100%;
            border-left: to-rem(1) solid $gray-ed;

            .product {
                position: unset;
            }
        }
