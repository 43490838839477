.dashboard-loans {
    margin: 0 to-rem(10);
}

.dashboard-loans__header {
    margin-bottom: to-rem(12);
}

.dashboard-loans__title {
    position: relative;
    top: 0;
    margin: 0;
}

.dashboard-loans__body--without-title {
    margin-top: to-rem(37);
}

.dashboard-loans__assets {
    width: to-rem(424);
}

.dashboard-loans__body {
    display: flex;
    flex-direction: column;

    .dashboard-loans__list {
        display: flex;
        align-items: center;
        width: to-rem(400);
        height: to-rem(153);
    }
}

