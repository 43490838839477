.product {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

    .product__img {
        width: to-rem(40);
        height: auto;
    }

    .product__mu {
        position: absolute;
        bottom: -0.5rem;
    }

.product--with-mu {
    width: to-rem(60);
    height: to-rem(48);
}
