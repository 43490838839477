.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

    .login__logo {
        width: to-rem(356);
        height: auto;
    }

    .login__form {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        width: 100%;
        max-width: to-rem(360);
    }


        .login__input {
            margin-bottom: 1rem;
            height: to-rem(60);
            color: $white;
            font-weight: $font-weight-bold;
            text-align: center;
            background-color: $gray-c4;
            border: 4px solid $black;
            border-radius: 10px;

            &::placeholder {
                color: $white;
            }
        }
