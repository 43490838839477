.product-quality-level {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 28.5rem;
}

.product-quality-level__name {
    @include font-size(12px);

    flex: 0.3;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}

@each $level, $color in $levels {
    .product-quality-level__name--#{$level} {
        color: $color;
    }
}

.product-quality-level__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    position: relative;
    z-index: 1;
    padding: 0 0.2rem;
    height: 1.8rem;
}

.product-quality-level__list--big {
    margin-bottom: 0.3rem;
    height: 2.4rem;
}

@each $level, $color in $levels {
    .product-quality-level__list--#{$level} {
        background: $color;
    }
}

.product-quality-level__point {
    z-index: 3;
}

.product-quality-level__product {
    position: absolute;
    top: to-rem(-9);
    z-index: 4;
    height: to-rem(18);
}

.product-quality-level__product--good {
    left: to-rem(2);
}

.product-quality-level__product--very-good {
    right: 0;
}

.product-quality-level__line {
    position: absolute;
    z-index: 2;
    width: calc(100% - 0.4rem);
    border-top: 1px solid $white;
}
