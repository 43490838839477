.primary-button, %primary-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: $focus-z-index;
    margin-left: auto;
    height: 5rem;
    min-width: 14.7rem;
    color: $white;
    font-weight: $font-weight-bold;
    background-color: $primary;
    border: 3px solid $black;
    border-radius: 15px;
    box-shadow: inset 0 2px 0 1px $primary-extra-light,
                inset 0 8px 0 1px $primary-light,
                inset 0 0 0 3px $primary-light;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.05);
    }

    &:focus-visible {
        outline: 0;
    }
}

.primary-button--disabled {
    background: $gray-5c;
    box-shadow: inset 0 2px 0 1px $gray-c4,
                inset 0 8px 0 1px $gray-6d;
    cursor: not-allowed;

    .primary-button__next-step {
        color: $gray-ed;
    }
}

.primary-button__next-step {
    @include font-size(12px);

    color: $yellow;
}

.primary-button__action {
    @include font-size(28px);

    color: $white;
    line-height: 1;
    text-transform: uppercase;
}
