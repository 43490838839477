
.liquidity-calculation-year {
    display: flex;
    width: to-rem(333);
    min-height: to-rem(30);
    text-transform: uppercase;
    border-left: 1px solid $gray-ed;
    flex-basis: 9.1%;
}

.liquidity-calculation-year--header {
    height: 100%;
}

.liquidity-calculation-year--initial {
    background-color: $purple-lighter;

    .liquidity-calculation-year__sides {
        background-color: $purple-lighter;
    }

    .liquidity-calculation-year__quarters--disabled {
        background-color: $purple-lighter;
    }
}

.liquidity-calculation-year--regular {
    background-color: $gray-d4;

    .liquidity-calculation-year__sides {
        background-color: $gray-d4;
    }

    .liquidity-calculation-year__sides--disabled {
        background-color: $gray-aa;
    }
}

    .liquidity-calculation-year__quarter {
        width: to-rem(35);
        text-align: center;
    }

    .liquidity-calculation-year__sides {
        display: flex;
        align-items: center;
        justify-content: center;
        width: to-rem(100);
        font-size: to-rem(12);
        background-color: $gray-c4;
    }

    .liquidity-calculation-year__sides--disabled {
        background-color: $gray-aa;
    }

        .liquidity-calculation-year__sides__text {
            text-align: center;
        }

    .liquidity-calculation-year__quarters {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: to-rem(150);
        min-height: to-rem(30);
    }

    .liquidity-calculation-year__quarters--disabled {
        background-color: $gray-aa;
    }





