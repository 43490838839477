.product-communication {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 0.4rem;
    padding: 0.2rem 1.2rem 0.2rem 2.57rem;
    width: 12.8rem;
    background: $black;
    border-radius: $border-radius-sm;
    box-sizing: border-box;
}

.product-communication__product {
    position: absolute;
    left: -0.5rem;
    height: to-rem(19);
}
