.dashboard {}

.dashboard__tabs {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.dashboard__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: to-rem(10) to-rem(10);;
    width: 100%;
    background-color: $gray-d4;
    border: to-rem(4) solid $white;
    border-radius: 0 to-rem(20) to-rem(20) to-rem(20);
    box-sizing: border-box;
}
