.game-information {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    padding-top: to-rem(20);
    width: 100%;
    background-color: $gray-d4;
    border: to-rem(4) solid $white;
    border-radius: to-rem(20);
    box-sizing: border-box;
}

.game-information__box {
    margin-bottom: to-rem(20);
}

.game-information__table-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    height: to-rem(30);
    font-weight: $font-weight-bold;
    background-color: $gray-6d;
    border-radius: to-rem(10) to-rem(10) 0 0;
    box-sizing: border-box;

    .game-information__table-cell {
        min-height: auto;
    }
}

.game-information__table-cell {
    display: flex;
    align-items: center;
    padding: to-rem(8) 0 to-rem(8) to-rem(20);
    width: to-rem(250);
    min-height: to-rem(56);
    border-right: 2px solid $gray-d4;
    box-sizing: border-box;
}

.game-information__table-body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: fit-content;
    height: fit-content;
    background-color: $gray-c4;
    border-radius: 0 0 to-rem(10) to-rem(10);
}

.game-information__table-body--reduced {
    padding: 0;
}

.game-information__table-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    color: $black;
    font-weight: $font-weight-bold;
}

.game-information__worker {
    margin-right: to-rem(5);
    height: to-rem(40);
}

.game-information__factory {
    height: to-rem(40);
}

.game-information__product {
    height: to-rem(40);
}

.game-information__machine {
    height: to-rem(40);
}

.game-information__table-row--market {
    padding-bottom: 0;

    .game-information__table-cell--market {
        padding: 0;

        .market-analysis__market-title {
            padding: 0 to-rem(35);
            width: to-rem(250);
            height: to-rem(56);
            box-sizing: border-box;
        }
    }
}