.market-leader {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.7rem 1.3rem;
    width: 12.8rem;
    background: $gray-30;
    border-radius: $border-radius-sm;
    box-sizing: border-box;
}

.market-leader__title {
    @include font-size(12px);

    margin-bottom: 0.2rem;
    color: $gray-d8;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}

.market-leader__points {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
