.money-unit {
    @include font-size(12px);

    position: relative;
    // z-index: 0;
    padding: 0.1rem 0.5rem 0.1rem 1.5rem;
    max-width: to-rem(110);
    font-weight: $font-weight-bold;
    text-align: right;
    background-color: $gray-30;
    border-radius: $border-radius-sm;
    box-sizing: border-box;
}

    .money-unit__icon {
        position: absolute;
        top: to-rem(-2);
        left: to-rem(-7);
        z-index: 2;
        width: to-rem(24);
        height: to-rem(24);
    }

        .money-unit__amount {
            @include font-size(12px);
            margin-right: 0.2rem;
            color: $white;
        }

        .money-unit__mu {
            @include font-size(10px);
            color: $white;
        }


    .money-unit__popper {
        z-index: $help-z-index;
    }

.money-unit--top-bar {
    z-index: -2;
    padding: 0.3rem 1.5rem;
    height: 2.8rem;
    min-width: to-rem(172);
    background-color: $gray-6d;
    border: 3px solid $black;
    border-radius: $border-radius;

    &::after {
        position: absolute;
        top: to-rem(5);
        right: to-rem(6);
        z-index: -1;
        width: 90%;
        height: 75%;
        background-color: $gray-5c;
        border-radius: to-rem(14);
        content: '';
    }

    .money-unit__icon {
        top: to-rem(-5);
        width: to-rem(45);
        height: to-rem(45);
    }

    .money-unit__box {
        margin-top: to-rem(-2);
    }

    .money-unit__amount { @include font-size(21px); }

    .money-unit__mu { @include font-size(16px); }
}

.money-unit--arrow {
    display: flex;
    justify-content: center;
    margin-top: to-rem(8);
    height: to-rem(28);
    min-width: to-rem(102);
    background: $primary;
    border-radius: to-rem(14);

    &::before {
        position: absolute;
        top: to-rem(1);
        left: to-rem(-22);
        width: 0;
        height: 0;
        border-color: transparent transparent $primary;
        border-style: solid;
        border-width: 0 to-rem(21) to-rem(25);
        border-radius: to-rem(4);
        content: '';
        transform: rotate(270deg);
    }

    .money-unit__icon {
        position: relative;
        top: to-rem(2);
        width: to-rem(21);
        height: to-rem(21);
    }

    .money-unit__q {
        @include font-size(12px);

        position: absolute;
        top: to-rem(-20);
        right: 0;
        margin-bottom: 1rem;
        padding-right: 0.5rem;
        width: 100%;
        color: $yellow;
        font-weight: $font-weight-bold;
        text-align: right;
    }

    .money-unit__amount { @include font-size(16px); }

    .money-unit__mu { @include font-size(12px); }

}

.money-unit--bordered {
    height: 1.28rem;
    line-height: 1;
    border: 2px solid $white;
    box-sizing: border-box;

    .money-unit__icon {
        top: to-rem(-5);
    }
}

.money-unit--procurement {
    .money-unit__box{
        display: flex;
        align-items: center;
        .money-unit__amount{
            min-width: to-rem(8);
        }
    }
}

.money-unit--employee,
.money-unit--rd {
    .money-unit__icon {
        left: to-rem(-4);
        width: auto;
        height: to-rem(24);
    }
}

.money-unit--building {
    .money-unit__icon {
        height: to-rem(22);
    }
}

.money-unit--lg {
    padding-top: to-rem(3);
    padding-right: 0.6rem;
    width: to-rem(84);
    height: to-rem(24);
    border-radius: 20px;

    .money-unit__icon {
        top: to-rem(-2);
        width: to-rem(28);
        height: to-rem(28);
    }
}

.money-unit--xl {
    padding-top: 0.3rem;
    padding-right: 0.6rem;
    width: to-rem(78);
    height: to-rem(24);
    border-radius: 20px;

    .money-unit__icon {
        top: to-rem(-4);
        width: to-rem(32);
        height: to-rem(32);
    }
}

.money-unit--quarter-xl {
    padding-top: 0.3rem;
    padding-right: 0.6rem;
    width: to-rem(112);
    height: to-rem(24);
    border-radius: 20px;

    .money-unit__icon {
        top: to-rem(-4);
        width: to-rem(32);
        height: to-rem(32);
    }
}

// Vertical MUs

.money-unit--vertical, %money-unit--vertical {
    display: flex;
    position: relative;
    z-index: 100;
    padding: 0;
    width: to-rem(44);
    background-color: transparent;

    .money-unit__icon {
        position: absolute;
        top: -1.2rem;
        right: 0;
        left: 0;
        z-index: -100;
        margin-right: auto;
        margin-left: auto;
        width: to-rem(22);
        height: to-rem(22);
    }

    .money-unit__box {
        z-index: 100;
        padding: 0 0.3rem;
        width: 100%;
        font-weight: $font-weight-bold;
        text-align: center;
        background-color: $black;
        border-radius: 10px;
    }

    .money-unit__amount {
        @include font-size(10px);
        line-height: to-rem(14);
    }

    .money-unit__mu {
        @include font-size(8px);
        line-height: to-rem(14);
    }
}

.money-unit--account-receivables {
    @extend %money-unit--vertical;
    flex-direction: column;
    align-items: center;
    top: to-rem(-5);
    height: to-rem(40);

    .money-unit__icon {
        position: relative;
        top: 0;
    }

    .money-unit__box {
        background-color: $primary;
    }

}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-40px);}
    60% {transform: translateY(-15px);}
}

.animated {
    animation: bounce 1s forwards;
}

