.account-receivables {
    display: flex;
    flex-direction: row;
    height: 100%;
}

    .account-receivables__money {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: to-rem(10);
        width: to-rem(70);
        height: to-rem(66);
        background: $gray-30;
        border-radius: $border-radius-sm;
        box-sizing: border-box;

        &:hover {
            background: $gray-39;
        }
    }

    .account-receivables__quarter {
        @extend %btn;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        margin-top: to-rem(10);
        margin-right: to-rem(5);
        width: to-rem(70);
        cursor: pointer;

        &:disabled {
            cursor: not-allowed;
      }
    }

        .account-receivables__quarter-text {
            font-weight: $font-weight-bold;
            align-self: center;
        }

    .account-receivables__vector-up {
        position: absolute;
        top: to-rem(-9);
        left: to-rem(22);
    }

    .account-receivables__vector {
        position: absolute;
        top: to-rem(21);
        left: to-rem(-10);
        height: to-rem(21);
    }

.accounts-factor {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: to-rem(160);
}

.account-receivables-button {
    display: flex;
    z-index: $focus-z-index;
    margin: auto;
    width: 19rem;
}

.account-receivables-button__coin {
    margin-right: 0.5rem;
    width: to-rem(16);
    height: to-rem(16);
}

.account-receivables-button__cost {
    @include font-size(12px);
}

.account-receivables-button__separator {
    margin: 0 to-rem(11);
    width: to-rem(1);
    height: to-rem(12);
}

.account-receivables-button__separator--positive {
    border-right: 2px solid $green-c4;
}
