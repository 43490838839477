.my-orders {
    margin-top: 1rem;
    margin-left: to-rem(6);
    max-width: to-rem(1335);
}

.my-orders__list {
    position: relative;
}

.my-orders__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: to-rem(28);
    width: 100%;

    &:after {
        flex: auto;
        content: '';
    }
}

.my-orders__card-wrapper {
    margin-right: to-rem(12);
}

.my-orders__alert {
    position: relative;
    top: auto;
    left: 39%;
    transform: none;
}
