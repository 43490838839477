.competition-analysis-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: to-rem(10) to-rem(10) to-rem(10) to-rem(197);
    width: to-rem(1310);
    background: $gray-c4;
    border-radius: to-rem(16) to-rem(16) 0 0;
    box-sizing: border-box;
}

.competition-analysis-header__product {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: to-rem(261);
    height: to-rem(120);
    border-radius: $border-radius-sm;
    box-shadow: inset 0 4px 14px rgba($black, 0.25);
}

.competition-analysis-header__product-icon {
    margin-bottom: 0.5rem;
    height: to-rem(52);
}

.competition-analysis-header__product-name {
    display: flex;
    align-items: center;
    justify-content: center;
    width: to-rem(103);
    height: to-rem(30);
    font-weight: $font-weight-black;
    background-size: 100%;
    background-image: url('../../assets/icons/product-label.svg');
}
