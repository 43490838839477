.conclusion {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 to-rem(30);
    box-sizing: border-box;
}

.conclusion__subtitle {
    margin-bottom: to-rem(20);
    text-transform: uppercase;
}

.conclusion__text {
    @include font-size(12px);

    color: $gray-6d;
    font-weight: $font-weight-bold;
}
