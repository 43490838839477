.employees {
    display: flex;
    flex-direction: column;
    z-index: $focus-z-index;
    margin-bottom: 0.5rem;
    padding: 0.64rem;
    width: to-rem(124);
    height: to-rem(112);
    background: $gray-2a;
    border: 1px solid $black;
    border-radius: $border-radius-xs;
    box-sizing: border-box;
}

.employees__title {
    @include font-size(12px);

    color: $gray-d8;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}

.employees__hire-button {
    @include font-size(10px);

    padding: 0.14rem 0.28rem;
    color: $white;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    background: $lightblue;
    border: 0;
    border-radius: $border-radius-xs;
    cursor: pointer;
}

.employees__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: to-rem(9);
    height: 100%;

    > * {
        flex: 50%;

        &:nth-child(2n) {
            justify-content: flex-end;
        }
    }
}

.employees__manager {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: to-rem(9);
    height: 100%;
}
