.loans-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: to-rem(-4);
    right: to-rem(-4);
    bottom: to-rem(-4);
    left: to-rem(-4);
    z-index: $modal-z-index;
    background: $dark-overlay;
    border-radius: $border-radius;
}

.loans-modal__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.loans-modal__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: to-rem(460);

}
