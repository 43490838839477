.market-analysis {
    display: flex;
    flex-direction: column;
    width: to-rem(1320);
    height: to-rem(640);
    background-color: $gray-d4;
    border: to-rem(4) solid $white;
    border-radius: 0 to-rem(20) to-rem(20);
    box-sizing: border-box;
    overflow-x: scroll;
    overflow-y: hidden;
}

    .market-analysis__input {
        @include font-size(12px);

        flex: 1;
        width: to-rem(10);
        font-weight: $font-weight-bold;
        text-align: center;
        background: transparent;
        border: 0;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .market-analysis__markets-column {
        width: to-rem(187);
        height: fit-content;
        flex-shrink: 0;
    }

    .market-analysis__data {
        display: flex;
        flex-direction: row;
    }

    .market-analysis__totals {
        @include font-size(12px);

        display: flex;
        flex-direction: row;
        align-items: center;
        width: to-rem(3562);
        min-height: to-rem(47);
        font-weight: $font-weight-bold;
        background-color: $gray-ed;
    }

    .market-analysis__totals__label {
        @include font-size(12px);

        padding: to-rem(10) 0 0 to-rem(20);
        width: to-rem(166);
        color: $black;
        font-weight: $font-weight-black;
        text-transform: uppercase;
        flex-shrink: 0;
    }

    .market-analysis__totals__space {
        display: flex;
        flex: 1;
    }

    .market-analysis__totals__numbers__group {
        display: flex;
        justify-content: space-around;
        flex: 2;
        padding-left: to-rem(1);
    }

    .market-analysis__totals__number {
        flex: 1;
        padding-top: to-rem(8);
        text-align: center;
    }

    img {
        &.market-analysis__product {
            width: to-rem(30);
            height: to-rem(20);
        }
    }

    .market-analysis__rows {
        border-bottom: to-rem(1) solid $gray-ed;
    }

    .market-analysis__year {
        @include font-size(12px);

        display: flex;
        align-items: center;
        justify-content: center;
        height: to-rem(70);
        font-weight: 900;
        text-transform: uppercase;
        background-color: $gray-6d;
    }

    .market-analysis__market-title {
        padding: to-rem(24) to-rem(32) to-rem(28.5) to-rem(18);
    }

    .market-analysis__market-title--sm {
        height: to-rem(69);
        padding: to-rem(10);
        box-sizing: border-box;

        .market-analysis__market-title__label {
            width: 80%;
        }
    }

    @each $position, $color in $markets-light {
        .market-analysis__market-title--#{$position} {
            background-color: $color;
        }
    }

        .market-analysis__market-title__label {
            @include font-size(12px);

            position: relative;
            margin: 0.5rem;
            margin-right: 1rem;
            padding: 0.65rem;
            width: 86%;
            color: $white;
            font-weight: $font-weight-bold;
            text-align: center;
            text-transform: uppercase;
            background-color: $market-first;
            border-radius: to-rem(5);
            box-shadow: inset 0 to-rem(3) to-rem(3) rgba($black, 0.25);

            &:before {
                position: absolute;
                top: 0.75rem;
                left: -0.5rem;
                width: to-rem(14);
                height: to-rem(14);
                background-color: $market-first;
                border-radius: 50%;
                box-shadow: inset to-rem(1) 0 to-rem(1) 0 rgba($black, 0.25);
                content: '';
            }

            &:after {
                position: absolute;
                top: 0.75rem;
                right: -0.5rem;
                width: to-rem(14);
                height: to-rem(14);
                background-color: $market-first;
                border-radius: 50%;
                box-shadow: inset to-rem(-1) 0 to-rem(1) 0 rgba($black, 0.25);
                content: '';
            }
        }

        @each $position, $color in $markets {
            .market-analysis__market-title__label--#{$position} {
                background-color: $color;

                &:before {
                    background-color: $color;
                }

                &:after {
                    background-color: $color;
                }
             }
         }


