.plant-inactive {
    justify-content: center;
}

.plant-inactive--slots-3 {
    height: to-rem(563);
}

.plant-inactive--slots-2 {
    height: to-rem(380);
}

.plant-inactive--slots-1 {
    height: to-rem(184);
}
