.product-performance-by-market {
    display: flex;
    flex-wrap: wrap;
    width: to-rem(582);
    height: to-rem(288);
}

.product-performance-by-market__graphic {
    width: to-rem(290);
    height: to-rem(140);
}
