.machine-workers {
    display: flex;
    justify-content: space-between;
    width: to-rem(171);
    height: to-rem(70);
    box-sizing: border-box;
}

.machine-workers--producing {
    animation-name: bounce-employees;
    animation-timing-function: ease;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.machine-workers__worker {
    width: to-rem(40);
    height: to-rem(70);
}

.machine-workers--buy {
    justify-content: flex-start;
    height: to-rem(50);

    .machine-workers__worker {
        margin-right: 1rem;
        width: to-rem(28);
        height: to-rem(50);
    }
}
