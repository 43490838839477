.machine-stand__table-legs {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    bottom: to-rem(-20);
    width: to-rem(226);
}

.machine-stand__table {
    height: to-rem(8);
    background: $gray-6d;
    border-radius: 0 $border-radius $border-radius 0;
}

.machine-stand__legs {
    display: flex;
    justify-content: space-between;
    margin: 0 1rem;
}

.machine-stand__leg {
    width: to-rem(8);
    height: to-rem(15);
    background: $gray-6d;
    border-radius: 0 0 to-rem(3) to-rem(3);
}

.machine-stand__continous-track {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: to-rem(-12);
    padding: 0 1rem 0 to-rem(64);
    width: to-rem(276);
    height: to-rem(20);
    border-radius: 0 $border-radius $border-radius 0;
    box-sizing: border-box;
}

.machine-stand__continous-track--full-semi-automatic {
    background: $gray-6d;
}

.machine-stand__continous-track--full-automatic {
    background: $gray-30;
}

.machine-stand__wheel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    background: $gray-c4;
    border-radius: 50%;

    &:after {
        width: to-rem(4);
        height: to-rem(4);
        border-radius: 50%;
        content: '';
    }
}

.machine-stand__wheel--full-semi-automatic {
    &:after {
        background: $gray-6d;
    }
}

.machine-stand__wheel--full-automatic {
    &:after {
        background: $gray-30;
    }
}
