.cumulative-revenue-by-products-in-markets {
    display: flex;
    flex-wrap: wrap;
    width: to-rem(695);
    height: to-rem(360);
}

.cumulative-revenue-by-products-in-markets__graphic {
    width: to-rem(347);
    height: to-rem(140);
}
