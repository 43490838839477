$action-position: -1rem;

.add-delete-button {
    @include font-size(12px);

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 3.3rem;
    height: 1.2rem;
    color: $white;
    font-weight: $font-weight-bold;
    background: $gray-30;
    border-radius: $border-radius;
}

.add-delete-button__text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: to-rem(50);
}

.add-delete-button__action {
    position: absolute;
    width: 1.7rem;
    height: 1.7rem;
}

.add-delete-button__action--delete {
    left: $action-position;
}

.add-delete-button__action--add {
    right: $action-position;
}
