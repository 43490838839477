.investment-calculation {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 to-rem(30);
    box-sizing: border-box;
}

.investment-calculation__subtitle {
    margin-bottom: to-rem(20);
    text-transform: uppercase;
}

.investment-calculation__content {
    width: to-rem(1252);
    overflow-x: auto;
}

.investment-calculation__table {
    font-weight: $font-weight-black;
    width: 100%;

    tr:first-child th:first-child {
        border-top-left-radius: $border-radius-sm;
    }

    tr:first-child th:last-child {
        border-top-right-radius: $border-radius-sm;
    }

    tr:last-child td:first-child {
        border-bottom-left-radius: $border-radius-sm;
    }

    tr:last-child td:last-child {
        border-bottom-right-radius: $border-radius-sm;
    }
}

.investment-calculation__table-head {
    @include font-size(12px);

    height: to-rem(40);
    background: $gray-6d;
    border-radius: $border-radius-xs $border-radius-xs 0 0;

    tr {
        th:first-child {
            width: to-rem(250);
            min-width: to-rem(250);
            text-align: left;
        }

        th:nth-child(2) {
            width: to-rem(125);
            min-width: to-rem(125);
        }
    }

    th {
        border: 0;
        padding: 0 to-rem(8);
        min-width: to-rem(60);
    }
}

.investment-calculation__subtext {
    @include font-size(10px);
}

.investment-calculation__table-body {
    @include font-size(12px);

    background: $gray-c4;
    color: $gray-30;

    td {
        padding: to-rem(8)
    }
}

.investment-calculation__table-cell {
    text-align: center;
}

.investment-calculation__table-cell--read-only {
    background: $gray-aa;
}

.investment-calculation__table-cell {
    text-align: center;
}

.investment-calculation__table-cell--total {
    background: $green-ca;
}

.investment-calculation__table-cell--product {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.investment-calculation__product-image {
    height: to-rem(40);
}

@each $position, $color in $markets {
    .investment-calculation__table-market-cell--#{$position} {
        color: $white;
        background-color: $color;
    }
}

.investment-calculation__table-desinvestment {
    background-color: $green-e4;
}
