.machine-installing {
    margin-top: to-rem(46);
    position: relative;
}

.machine-installing__time {
    position: absolute;
    top: to-rem(28);
    right: to-rem(88);
}

.machine-installing__img {
    width: to-rem(275);
    height: to-rem(90);
}
