.participants-form-textarea {
    display: flex;
    flex-direction: column;
}

.participants-form-textarea__label {
    @include font-size(12px);

    padding: to-rem(10) to-rem(20);
    background: $green-ca;
    color: $gray-6d;
    font-weight: $font-weight-bold;
    border-radius: $border-radius-sm $border-radius-sm 0 0;
}

.participants-form-textarea__input {
    @include font-size(12px);

    padding: to-rem(10) to-rem(20);
    background: $green-e4;
    color: $gray-30;
    border-radius: 0 0 $border-radius-sm $border-radius-sm;
}
