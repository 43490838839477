.procurement-product {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.procurement-product__icon {
    max-height: to-rem(28);
}

.procurement-product__slider {
    margin-right: 1.5rem;
}

.procurement-product__content {
    width: 100%;
    margin-right: 1.7rem;
}

.procurement-product__hire {
    display: flex;
    justify-content: center;
    width: 100%;
}

.procurement-product__hire-slider {}

.procurement-product__hire-slider--top {
    display: flex;
    align-items: center;
    justify-content: center;
}

    .procurement-product__hire-btn {
        width: to-rem(70);
        height: to-rem(28);

        &::before {
            width: to-rem(63);
            height: to-rem(19);
        }

        .action-button__text {
            @include font-size(15px);
        }
    }

