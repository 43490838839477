.facilitators {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 5rem;
}

    .facilitators__title {
        @include font-size(28px);
        margin-bottom: 1rem;
        color: $white;
        font-weight: $font-weight-bold;
        line-height: 42px;
        text-transform: uppercase;
    }

    .facilitators__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

        .facilitators__games {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 2rem;
        }

            .facilitators__game-card {
                @include font-size(16px);

                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                margin: 1rem;
                padding: 2rem;
                width: to-rem(352);
                height: to-rem(232);
                color: $white;
                background-image: url('../../assets/backgrounds/stars.png'),
                                  linear-gradient(180deg, $primary 0%, $secondary 100%);
                border: 3px solid $black;
                border-radius: 7px;

                &:hover, &:active, &.active, &:focus {
                    box-shadow: 0 0 10px $primary;
                }

                &.active {
                    cursor: default;
                }

                &.hidden {
                    display: none;
                }
            }

                .facilitators__game-logo {
                    margin-bottom: 1rem;
                    width: to-rem(94);
                    height: auto;
                }

                .facilitators__game-title {
                    font-weight: $font-weight-black;
                }

                .facilitators__game-date {
                    margin-left: 0.5rem;
                    font-weight: $font-weight-normal;
                }

            .facilitators__game-close {
                position: absolute;
                top: 1rem;
                right: 1rem;
                z-index: 1000;
            }

            .facilitators__select {
                width: to-rem(300);
                height: to-rem(50);
                text-align: center;
                text-transform: uppercase;
                color: $white;
                font-weight: $font-weight-bold;
                background: rgba($black, 0.5);
                border: 2px solid $black;
                box-sizing: border-box;
                border-radius: 40px;
                outline: none;
                cursor: pointer;
            }

            .facilitators__submit {
                @include font-size(16px);

                font-family: $font-family-base;
                margin-right: auto;
                margin-left: auto;
                width: to-rem(360);
            }


.facilitator--centered {
    height: 100vh;
    margin-top: 0;
}
