.liquidity-calculation-row {
    display: flex;
    flex-direction: row;
    width: to-rem(3674);
    height: fit-content;
    font-weight: $font-weight-bold;
    border-bottom: 1px solid $gray-ed;
}

.liquidity-calculation-row--initial {
    text-transform: uppercase;
    background-color: $purple-lighter;

    .liquidity-calculation-row__label { background-color: $purple-lighter; }
}

    .liquidity-calculation-row__id {
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        left: 0;
        width: to-rem(53);
        min-height: to-rem(30);
        font-size: to-rem(12);
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        background-color: $gray-d4;
        border-right: 1px solid $gray-ed;
    }

    .liquidity-calculation-row__id--initial {
        background-color: $purple-light;
    }

    .liquidity-calculation-row__id--third-light {
        background-color: $market-third-light;
    }

    .liquidity-calculation-row__id--fifth-light {
        background-color: $market-fifth-light;
    }

    .liquidity-calculation-row__label {
        @include font-size(12px);

        display: flex;
        align-items: center;
        position: sticky;
        left: to-rem(53);
        padding-left: to-rem(15);
        min-height: to-rem(30);
        background-color: $gray-d4;
        border-right: 1px solid $gray-ed;
        flex-basis: 7.66%;
        box-sizing: border-box;
        flex-shrink: 0;
    }
