.marketing-board-tabs {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -4rem;
    right: -11.6rem;
}

.marketing-board-tabs__box-border {
    border-radius: $border-radius 0 $border-radius $border-radius;
}

@each $position, $color in $markets {
    .marketing-board-tabs__tab--#{$position} {
        @include font-size(12px);

        display: flex;
        align-items: center;
        padding-left: 0.7rem;
        width: 9.3rem;
        height: 2.4rem;
        margin-bottom: 0.3rem;
        color: $color;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        background-color: map.get($markets-light, #{$position}-light);
        border-radius: 0 $border-radius $border-radius 0;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;

        &:after {
            position: absolute;
            top: to-rem(4);
            right: to-rem(4);
            width: to-rem(12);
            height: to-rem(8);
            background-color: map.get($markets-light, #{$position}-light);
            border-radius: 50%;
            transform: rotate(20deg);

            content: '';
        }
    }

    .marketing-board-tabs__tab--#{$position}-active {
        color: $white;
        background-color: $color;
        box-shadow: inset -2px 1px 0 3px map.get($markets-light, #{$position}-light);
        cursor: default;
    }

}
