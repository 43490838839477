.production-plan {}

    .production-plan__body {}

        .production-plan__row, .production-plan__row--empty {
            display: flex;
            width: to-rem(3675);
            height: to-rem(60);
            border-bottom: to-rem(1) solid $gray-ed;
            flex-shrink: 0;
        }

    .production-plan__input {
        @include font-size(12px);

        flex: 1;
        width: to-rem(40);
        font-weight: $font-weight-bold;
        text-align: center;
        background: transparent;
        border: 0;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .production-plan__machine-cell, .production-plan__machine-cell--empty {
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: sticky;
        left: 0;
        min-width: to-rem(350);
        border-right: 1px solid $gray-ed;
        background: $gray-d4;
        font-size: to-rem(12);
        font-weight: $font-weight-bold;
        flex-basis: 9.5%;
        box-sizing: border-box;
    }

    .production-plan__year, .production-plan__year--empty {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex: 1;
        font-size: to-rem(12);
        font-weight: $font-weight-bold;
        border-left: to-rem(1) solid $gray-ed;
    }

    .production-plan__row {}

        .production-plan__row-input {
            width: to-rem(45);
        }

    .production-plan__machine {
        width: to-rem(91);
        height: auto;

        .machine__box {
            padding-top: 0;
            height: to-rem(25);
            border-radius: $border-radius-sm $border-radius-sm 0 0;
        }

        .machine__band {
            width: to-rem(91);
            height: to-rem(20);
            border-radius: $border-radius-sm $border-radius-sm 0 0;
        }

        .machine__band--manual {
            height: to-rem(20);
            background-image: repeating-linear-gradient(
                to right,
                $gray-c4,
                $gray-c4 to-rem(1.4),
                $gray-ed to-rem(1.4),
                $gray-ed to-rem(10)
            );
        }

        .machine__band--full-semi-automatic {
            background-image: repeating-linear-gradient(
                to right,
                $gray-97,
                $gray-97 to-rem(1.4),
                $gray-b4 to-rem(1.4),
                $gray-b4 to-rem(7)
            );
        }

        .machine__band--full-automatic {
            background-image: repeating-linear-gradient(
                to right,
                $gray-30,
                $gray-30 to-rem(1.4),
                $gray-6d to-rem(1.4),
                $gray-6d to-rem(7)
            );
        }

        .machine__assigned-product {
            top: to-rem(-2);
        }

        .machine__quarter {
            margin-left: to-rem(19);
        }

        .machine__quarter--full-automatic {
            top: to-rem(-6);
            left: to-rem(14);
        }

        .machine-stand__table-legs {
            bottom: to-rem(-7);
            width: to-rem(75);
        }

        .machine-stand__table {
            height: to-rem(2.67);
        }

        .machine-stand__legs {
            margin: 0 0.5rem;
        }

        .machine-stand__leg {
            width: to-rem(3);
            height: to-rem(5);
            border-radius: 0 0 to-rem(1) to-rem(1);
        }

        .machine-stand__continous-track {
            bottom: to-rem(-6);
            padding: 0 to-rem(4) 0 to-rem(18.2);
            width: to-rem(91);
            height: to-rem(6);
            border-radius: 0 $border-radius-sm $border-radius-sm 0;
        }

        .assigned-product {
            width: to-rem(16);
            height: to-rem(30);
            border-radius: to-rem(2);
        }

        .assigned-product__box {
            margin: to-rem(0.9) to-rem(0.9) 0;
            padding-top: 0.25rem;
            height: to-rem(19);
            border-radius: to-rem(2);
        }

        .assigned-product__icon {
            max-width: to-rem(15);
        }

        .assigned-product__cost {
            bottom: to-rem(-1);
            left: to-rem(-2);
        }

        .assigned-product__foot {
            height: to-rem(9);
            border-radius: 0 0 to-rem(2.5) to-rem(2.5);
        }

        .machine-stand__wheel {
            width: 0.3rem;
            height: 0.3rem;
            border-radius: 50%;

            &:after {
                padding-top: to-rem(0.7);
                padding-left: to-rem(0.7);
                width: to-rem(1.5);
                height: to-rem(1.5);
                border-radius: 50%;
                content: '';
            }
        }

        .machine-quarter {
            width: to-rem(30);
            height: to-rem(31);
        }

        .machine-quarter--full-automatic {
            padding: to-rem(4) to-rem(5.33) to-rem(8.67);
            width: to-rem(36);
            height: to-rem(33);
            border-radius: to-rem(3);
        }

        .machine-quarter__full-automatic {
            width: to-rem(28);
            height: to-rem(22);
            background-image: repeating-linear-gradient(
                to right,
                $gray-97,
                $gray-97 to-rem(0.7),
                $gray-b4 to-rem(0.7),
                $gray-b4 to-rem(3.5)
            );

            border: to-rem(2) solid $white;
            border-radius: to-rem(3);
        }

        .machine-quarter__arms {
            top: to-rem(2);
            height: to-rem(9.8);
        }

        .machine-quarter__product {
            top: 0.4rem;
        }

    }

    .production-plan__footer {
        display: flex;
        flex-direction: row;
        position: absolute;
        bottom: 0;
        width: to-rem(3675);
        height: to-rem(123.5);
        background-color: $gray-c4;
    }

        .production-plan__footer-labels {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: sticky;
            left: 0;
            min-width: to-rem(350);
            background: $gray-c4;
            border-right: 1px solid $gray-ed;
            font-size: to-rem(12);
            font-weight: $font-weight-bold;
            flex-basis: 9.5%;
            box-sizing: border-box;
        }

            .production-plan__footer-label {
                margin-left: to-rem(28);
                padding: to-rem(8);
            }

            .production-plan__footer-label--editable {
                width: 100%;
                margin: 0;
                padding-left: to-rem(36);
                background: $gray-d4;
                box-sizing: border-box;
                border-bottom: 1px solid $white;
            }

        .production-plan__footer-row {
            display: flex;
            flex-direction: column;
            flex: 1;
            font-size: to-rem(12);
            font-weight: $font-weight-bold;
            border-left: 1px solid $gray-ed;
        }

            .production-plan__footer-cell {
                display: flex;
                justify-content: space-around;
                padding: to-rem(7) 0 to-rem(8);
                min-height: to-rem(17);
            }

            .production-plan__footer-cell--editable {
                background: $gray-d4;
                border-bottom: 1px solid $white
            }

            .production-plan__footer-number {
                width: to-rem(45);
                text-align: center;
            }
