.participants-form-input {
    @include font-size(12px);

    width: 100%;
    height: to-rem(40);
    margin-top: to-rem(10);
    padding: to-rem(10);
    background: $gray-c4;
    border: 0;
    border-radius: $border-radius-xs;
    color: $gray-6d;
    box-sizing: border-box;
}
