$cell-width: to-rem(187);

.market-position-row {
    display: flex;
    flex-direction: row;
    height: to-rem(112);
}

.market-position-row__header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $cell-width;
    background-color: $market-first-light;
}

.market-position-row__header--last {
    border-radius: 0 0 0 to-rem(16);
}

.market-position-row__header-name {
    @include font-size(12px);

    position: relative;
    margin: 0.5rem 0 0.7rem;
    padding: 0.5rem;
    width: to-rem(122);
    height: to-rem(36);
    color: $white;
    font-weight: $font-weight-bold;
    text-align: center;
    text-transform: uppercase;
    background-color: $market-first;
    border-radius: 5px;
    box-shadow: inset 0 3px 3px rgba($black, 0.25);
    box-sizing: border-box;

    &:before {
        position: absolute;
        top: to-rem(10);
        left: -0.5rem;
        width: to-rem(14);
        height: to-rem(14);
        background-color: $market-first;
        border-radius: 50%;
        box-shadow: inset 1px 0 1px 0 rgba($black, 0.25);
        content: '';
    }

    &:after {
        position: absolute;
        top: to-rem(10);
        right: -0.5rem;
        width: to-rem(14);
        height: to-rem(14);
        background-color: $market-first;
        border-radius: 50%;
        box-shadow: inset -1px 0 1px 0 rgba($black, 0.25);
        content: '';
    }
}

// Market row variants by market
@each $position, $color in $markets {
    .market-position-row__header--#{$position} {
        background-color: map.get($markets-light, #{$position}-light);
    }

    .market-position-row__header-name--#{$position} {
        background-color: $color;

        &:before, &:after {
            background-color: $color;
        }
    }
}

.market-position-row__cell {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $cell-width;
    height: 100%;
    background: $gray-d4;
    border-right: 1px solid $white;
    border-bottom: 1px solid $white;
    box-sizing: border-box;
}

.market-position-row__cell--last {
    border-radius: 0 0 to-rem(16);
}

.market-position-row__trophy {
    width: to-rem(90);
    height: to-rem(60);
}
