.product-portfolios {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: to-rem(221);
    height: to-rem(288);
    box-sizing: border-box
}

.product-portfolios__product {
    @include font-size(12px);

    display: flex;
    justify-content: center;
    margin-bottom: to-rem(14);
    color: $gray-30;
    font-weight: $font-weight-bold;

    .product-portfolios__product-quarters {
        position: relative;
        bottom: auto;
        margin: to-rem(5) 0 0 to-rem(14);
    }
}

.product-portfolios__product-image {
    width: to-rem(40);
}

.product-portfolios__product-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: to-rem(52);
}
