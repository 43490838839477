.market-share {
    display: flex;
    flex-wrap: wrap;
    width: to-rem(386);
    height: fit-content;
    padding: to-rem(18) to-rem(30);
    box-sizing: border-box;
}

.market-share__market {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: to-rem(15);
}

.market-share__graphic {
    width: to-rem(160);
    height: to-rem(160);
    margin-bottom: to-rem(10);
}
