.market-power-by-products {
    display: flex;
    flex-wrap: wrap;
    width: to-rem(854);
    height: to-rem(612);
    padding: to-rem(10);
    box-sizing: border-box;
}

.market-power-by-products__graphic {
    width: to-rem(410);
    height: to-rem(240);
}
