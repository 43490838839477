.buy-machine {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $overlay;
    z-index: $modal-z-index;
}

    .buy-machine__wrapper {
        display: flex;
        flex-direction: column;
        padding-left: to-rem(300);
        max-height: calc(100vh - (#{$header-height} + #{$footer-height}));
        overflow: auto;
    }

    .buy-machine__row {
        display: flex;
        flex-direction: row;
        margin-bottom: 1rem;
        padding: 1rem;
        width: to-rem(984);
        height: to-rem(175);
        background-color: $gray-d4;
        border-radius: $border-radius;
    }

        .buy-machine__box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-right: 1rem;
            width: to-rem(312);
            height: to-rem(168);
            border-radius: 10px;
            box-shadow: inset 0 4px 10px rgba($black, 0.25);
        }

            .buy-machine__img {
                margin: 1rem 0;
                height: to-rem(80);
            }

            .buy-machine__btn {
                max-width: to-rem(272);
            }

    .buy-machine__title {
        @include font-size(21px);

        display: flex;
        align-items: center;
        justify-content: center;
        width: to-rem(600);
        height: to-rem(50);
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        background: url('../../assets/icons/dark-label.png') no-repeat;
        background-size: contain;
    }

    .buy-machine__content {
        display: flex;
        flex-direction: row;
        padding-top: 0.5rem;
        width: 100%;
        height: to-rem(100);
    }

    .buy-machine__info {
        display: flex;
        flex-direction: column;
        border-right: 2px solid $gray-c4;
    }

        .buy-machine__mu {
            margin: 0.5rem 1rem 1rem 0.5rem;
        }

        .buy-machine__workers-text {
            @include font-size(16px);
            margin-right: 1rem;
            color: $gray-6d;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
        }

    .buy-machine__products {
        padding-left: 1rem;
        width: 100%;
        height: 100%;
        border-left: 2px solid $white;
    }

    .buy-machine__description {
        @include font-size(12px);

        margin-bottom: to-rem(10);
        color: $gray-6d;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }

    .buy-machine__products-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
