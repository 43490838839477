.cost-accounting {}

    .cost-accounting__sub-header__cells {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
    }

    img {
        &.cost-accounting__img {
            width: to-rem(30);
            height: to-rem(25);
        }
    }

