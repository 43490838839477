.employees-hired {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
}

.employees-hired__x {
    @include font-size(10px);

    margin-left: 0.5rem;
    color: $white;
    font-weight: $font-weight-bold;
}

.employees-hired__count {
    @include font-size(12px);

    margin-left: 0.2rem;
    color: $white;
    font-weight: $font-weight-bold;
}

.employees-hired__icon {
    height: to-rem(31);
}

.employees-hired--salaries {
    color: $black;

    .employees-hired__icon {
        height: to-rem(40);
    }

    .employees-hired__count, .employees-hired__x {
        @include font-size(16px);
        color: $black;
    }
}
