.alert-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: $modal-z-index;
    padding: 1rem 2rem;
    width: 100%;
    max-width: to-rem(320);
    min-height: to-rem(110);
    color: $black;
    background: $white;
    border: 4px solid $black;
    border-radius: $border-radius-sm;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
}

.alert-modal--md {
    width: to-rem(320);
}

.alert-modal--lg {
    max-width: to-rem(420) !important;
}

.alert-modal--fixed {
    position: fixed;
}

.alert-modal__title {
    @include font-size(22px);

    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}

.alert-modal__body {
    @include font-size(12px);

    font-weight: $font-weight-bold;
    text-align: center;
}

.alert-modal__buttons {
    display: flex;
    margin-top: 1.5rem;
}
