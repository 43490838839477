.market-forecasts {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 to-rem(30);
    box-sizing: border-box;
    justify-content: space-around;
}
.market-forecasts__container {
    margin-bottom: 2rem;
}

.market-forecasts__subtitle {
    margin-top: 1rem;
    margin-bottom: to-rem(2);
    text-transform: uppercase;
}

.market-forecasts__text {
    @include font-size(12px);

    color: $gray-39;
    font-weight: $font-weight-bold;
}

.market-forecasts__text-paragraph {
    @include font-size(12px);

    margin-top: 1rem;
    color: $gray-39;
    font-weight: $font-weight-bold;
}