.current-product-quality {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: to-rem(221);
    height: to-rem(274);
}

.current-product-quality__index {
    display: flex;
    align-items: center;
    justify-content: center;
    width: to-rem(161);
    height: to-rem(158);
    margin-bottom: to-rem(10);
    border-radius: $border-radius-sm;
}

@each $level, $color in $levels {
    .current-product-quality__index--#{$level} {
        background: $color;
    }

    .current-product-quality__label--#{$level} {
        background: $color;
    }
}

.current-product-quality__label {
    @include font-size(21px);

    display: flex;
    align-items: center;
    justify-content: center;
    width: to-rem(161);
    height: to-rem(70);
    font-weight: $font-weight-bold;
    border-radius: $border-radius-sm;
    text-transform: uppercase;
}
