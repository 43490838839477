.rd-employees {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    position: relative;
    margin-right: 1rem;
    padding: 0.3rem 0.3rem 0.3rem 1rem;
    width: 100%;
    height: to-rem(42);
    background: $gray-30;
    border-radius: $border-radius;
    box-sizing: border-box;
}

.rd-employees__name {
    @include font-size(12px);

    color: $gray-d8;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}

.rd-employees__content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 1rem;
}

.rd-employees__list {
    display: flex;
    align-items: center;
}

.rd-employees__engineer {
    position: relative;
    margin-right: 1.6rem;
}

.rd-employees__engineer-icon {
    height: to-rem(27);
}


.rd-employees__remove-engineer {
    position: absolute;
    top: to-rem(-4);
    right: to-rem(-4);
    cursor: pointer;
}

.rd-employees__points {
    position: absolute;
    bottom: to-rem(-16);
    left: to-rem(-2);
    z-index: 1;
}

.rd-employee__point-arrow {
    position: absolute;
    top: to-rem(40);
    left: to-rem(58);
    height: to-rem(35);
}

.rd-employee__accumulated {
    position: absolute;
    top: to-rem(45);
    left: to-rem(50);
}
