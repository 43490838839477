.assigned-product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: to-rem(50);
    height: to-rem(90);
    background: $gray-6d;
    border-radius: $border-radius-xs;
    box-sizing: border-box;
}

.assigned-product--full-automatic {
    background: $gray-30;

    .assigned-product__box {
        border-color: $black;
    }

    .assigned-product__foot {
        background: $black;
    }
}

.assigned-product__box {
    position: relative;
    margin: to-rem(4) to-rem(4) 0;
    padding-top: 0.5rem;
    height: to-rem(58);
    border: 1px solid $gray-30;
    border-radius: $border-radius-xs;
    box-sizing: border-box;
}

.assigned-product__icon {
    max-width: to-rem(30);
}

.assigned-product__cost {
    position: absolute;
    bottom: to-rem(-1);
    left: to-rem(-4);
}

.assigned-product__foot {
    height: to-rem(24);
    background: $gray-30;
    border-radius: 0 0 $border-radius-xs $border-radius-xs;
}
