.box-title, %box-title {
    @include font-size(21px);

    margin: 0;
    color: $white;
    font-weight: $font-weight-black;
    text-shadow:  $gray-30 3px 0 0,
                  $gray-30 2.83487px 0.981584px 0,
                  $gray-30 2.35766px 1.85511px 0,
                  $gray-30 1.62091px 2.52441px 0,
                  $gray-30 0.705713px 2.91581px 0,
                  $gray-30 -0.287171px 2.98622px 0,
                  $gray-30 -1.24844px 2.72789px 0,
                  $gray-30 -2.07227px 2.16926px 0,
                  $gray-30 -2.66798px 1.37182px 0,
                  $gray-30 -2.96998px 0.42336px 0,
                  $gray-30 -2.94502px -0.571704px 0,
                  $gray-30 -2.59586px -1.50383px 0,
                  $gray-30 -1.96093px -2.27041px 0,
                  $gray-30 -1.11013px -2.78704px 0,
                  $gray-30 -0.137119px -2.99686px 0,
                  $gray-30 0.850987px -2.87677px 0,
                  $gray-30 1.74541px -2.43999px 0,
                  $gray-30 2.44769px -1.73459px 0,
                  $gray-30 2.88051px -0.838247px 0;
    text-transform: uppercase;
}

.box-title--xxs, %box-title--xxs {
    @include font-size(21px);

    margin: 0;
    color: $white;
    font-weight: $font-weight-black;
    text-shadow:  $gray-30 1.5px 0 0,
                  $gray-30 1.417435px 0.490792px 0,
                  $gray-30 1.17883px 0.927555px 0,
                  $gray-30 0.81045px 1.262205px 0,
                  $gray-30 0.352713px 1.457905px 0,
                  $gray-30 -0.143635px 1.49311px 0,
                  $gray-30 -0.62422px 1.363945px 0,
                  $gray-30 -1.03511px 1.08463px 0,
                  $gray-30 -1.33390px 0.68591px 0,
                  $gray-30 -1.48494px 0.21168px 0,
                  $gray-30 -1.49251px -0.285852px 0,
                  $gray-30 -1.29293px -0.751915px 0,
                  $gray-30 -0.99045px -1.135205px 0,
                  $gray-30 -0.05006px -1.39352px 0,
                  $gray-30 -0.68559px -1.49843px 0,
                  $gray-30 0.425493px -1.438385px 0,
                  $gray-30 0.872805px -1.219995px 0,
                  $gray-30 1.22389px -0.867295px 0,
                  $gray-30 1.44025px -0.4191235px 0;
    text-transform: uppercase;
}

    .box-title--finances {
        text-transform: none;
    }

.box-title--subtitle, %box-title--subtitle {
    @include font-size(16px);

    margin-top: to-rem(25);
    color: $black;
    font-weight: $font-weight-bold;
    text-shadow: $white 3px 0 0,
    $white 2.83487px 0.981584px 0,
    $white 2.35766px 1.85511px 0,
    $white 1.62091px 2.52441px 0,
    $white 0.705713px 2.91581px 0,
    $white -0.287171px 2.98622px 0,
    $white -1.24844px 2.72789px 0,
    $white -2.07227px 2.16926px 0,
    $white -2.66798px 1.37182px 0,
    $white -2.96998px 0.42336px 0,
    $white -2.94502px -0.571704px 0,
    $white -2.59586px -1.50383px 0,
    $white -1.96093px -2.27041px 0,
    $white -1.11013px -2.78704px 0,
    $white -0.137119px -2.99686px 0,
    $white 0.850987px -2.87677px 0,
    $white 1.74541px -2.43999px 0,
    $white 2.44769px -1.73459px 0,
    $white 2.88051px -0.838247px 0;
}

.box-title--sm, %box-title--sm {
    @include font-size(16px);
    text-transform: none;
}

.box-title--green, %box-title--green {
    text-shadow: $green-4a 2px 0 0,
                 $green-4a 1.75517px 0.958851px 0,
                 $green-4a 1.0806px 1.68294px 0,
                 $green-4a 0.141474px 1.99499px 0,
                 $green-4a -0.832294px 1.81859px 0,
                 $green-4a -1.60229px 1.19694px 0,
                 $green-4a -1.97998px 0.28224px 0,
                 $green-4a -1.87291px -0.701566px 0,
                 $green-4a -1.30729px -1.5136px 0,
                 $green-4a -0.421592px -1.95506px 0,
                 $green-4a 0.567324px -1.91785px 0,
                 $green-4a 1.41734px -1.41108px 0,
                 $green-4a 1.92034px -0.558831px 0;
}

.box-title--red, %box-title--red {
    text-shadow: $red-74 2px 0 0,
                 $red-74 1.75517px 0.958851px 0,
                 $red-74 1.0806px 1.68294px 0,
                 $red-74 0.141474px 1.99499px 0,
                 $red-74 -0.832294px 1.81859px 0,
                 $red-74 -1.60229px 1.19694px 0,
                 $red-74 -1.97998px 0.28224px 0,
                 $red-74 -1.87291px -0.701566px 0,
                 $red-74 -1.30729px -1.5136px 0,
                 $red-74 -0.421592px -1.95506px 0,
                 $red-74 0.567324px -1.91785px 0,
                 $red-74 1.41734px -1.41108px 0,
                 $red-74 1.92034px -0.558831px 0;
}

.box-title--gray, %box-title--gray {
    text-shadow: $gray-30 2px 0 0,
                 $gray-30 1.75517px 0.958851px 0,
                 $gray-30 1.0806px 1.68294px 0,
                 $gray-30 0.141474px 1.99499px 0,
                 $gray-30 -0.832294px 1.81859px 0,
                 $gray-30 -1.60229px 1.19694px 0,
                 $gray-30 -1.97998px 0.28224px 0,
                 $gray-30 -1.87291px -0.701566px 0,
                 $gray-30 -1.30729px -1.5136px 0,
                 $gray-30 -0.421592px -1.95506px 0,
                 $gray-30 0.567324px -1.91785px 0,
                 $gray-30 1.41734px -1.41108px 0,
                 $gray-30 1.92034px -0.558831px 0;
}

.box-title--purple, %box-title--purple {
    text-shadow: $purple-4b 2px 0 0,
                 $purple-4b 1.75517px 0.958851px 0,
                 $purple-4b 1.0806px 1.68294px 0,
                 $purple-4b 0.141474px 1.99499px 0,
                 $purple-4b -0.832294px 1.81859px 0,
                 $purple-4b -1.60229px 1.19694px 0,
                 $purple-4b -1.97998px 0.28224px 0,
                 $purple-4b -1.87291px -0.701566px 0,
                 $purple-4b -1.30729px -1.5136px 0,
                 $purple-4b -0.421592px -1.95506px 0,
                 $purple-4b 0.567324px -1.91785px 0,
                 $purple-4b 1.41734px -1.41108px 0,
                 $purple-4b 1.92034px -0.558831px 0;
}

.box-title--quarterly, %box-title--quarterly {
    @extend %box-title--subtitle;

    position: absolute;
    top: to-rem(-35);
}

.box-title--factory, %box-title--factory {
    @extend %box-title--subtitle;

    position: absolute;
    top: to-rem(-35);
    color: $gold;
}

.box-title--dashboard, %box-title--dashboard {
    @extend %box-title--quarterly;
    top: to-rem(-55);
    left: to-rem(1);
    text-transform: capitalize;
}

.box-title--dashboard-light {
    @extend %box-title--dashboard;
}

.box-title--kpi {
    @extend %box-title--subtitle;

    margin-top: 0;
    margin-bottom: to-rem(10);
    text-transform: none;
}

.box-title--end {
    display: flex;
    align-items: center;
    justify-content: center;
    height: to-rem(65);
}
