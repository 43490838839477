.debt-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: to-rem(103);
    background-color: $gray-c4;
    border-radius: 0 0 to-rem(10) to-rem(10);
}


.debt-row__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-top: to-rem(10);
    margin-right: to-rem(5);
    width: to-rem(90);
    height: to-rem(83);
    background: $gray-c4;
    border-radius: to-rem(10);
    box-shadow: inset 0 to-rem(4) to-rem(10) $box-shadow-coin-container;
}

    .quarter {
        @include font-size(to-rem(10));
        margin: 0 auto;
        width: to-rem(24);
        height: to-rem(24);
        line-height: to-rem(24);
        text-align: center;
        background-color: $gray-d4;
        border-radius: to-rem(12);
        align-self: center;
    }

    .coin-header {
        display: inline-block;
        position: absolute;
        top: 0;
        z-index: 3;
        margin: 0 auto;
        width: to-rem(90);
        height: to-rem(20);
        font-size: to-rem(8);
        font-weight: $font-weight-bold;
        line-height: 22px;
        text-align: center;
        background: $purple;
        border-radius: to-rem(10) to-rem(10) 0 0;
    }

    .coin-header--purple {
        background: $purple;
    }

    .coin-header--green {
        background: $green;
    }

    .coin-header--red {
        background: $red-9a;
    }

    .interest {
        display: flex;
        justify-content: space-evenly;
        position: absolute;
        bottom: to-rem(6);
        width: 100%;
        font-size: to-rem(8);
    }

    .interest__text {
        display: inline-flex;

    }

    .interest__label {
        margin-right: to-rem(2);
        color: $gray-c4;
        font-weight: $font-weight-bold;
    }

    .interest__percentage {
        display: inline-flex;
        color: $yellow;
        font-weight: $font-weight-bold;
    }

    .interest__quarter {
        font-weight: $font-weight-bold;
    }

    .borrowed-capital__vector {
        position: absolute;
        top: to-rem(32);
        left: to-rem(-9);
        z-index: 20;
        height: to-rem(21);
    }

.debt-row__box--multiple {
    position: relative;
    cursor: pointer;

    .debt-row__loan--multiple-another {
        position: absolute;
        top: to-rem(-10);
        z-index: 1;
    }

    .debt-row__loan--multiple-first {
        top: 0;
        z-index: 10;
    }
}
