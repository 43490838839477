.market-analysis-row {
    display: flex;
    flex-direction: row;
    height: to-rem(34);
    background: $gray-d4;
    font-weight: $font-weight-bold;
}

.market-analysis-row--dark {
    background-color: $gray-c4;
}

.market-analysis-row--total {
    background: $gray-ed;
}

    .market-analysis-row__label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        background-color: $gray-c4; 
        box-sizing: border-box;
    }

        .market-analysis-row__label__text {
            padding-left: to-rem(30);
        }

    .market-analysis-row__products {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex: 2;
        border-left: to-rem(1) solid $gray-ed;
    }

    .market-analysis-row__products--empty {
        background-color: $gray-aa;

        &.market-analysis-row__products--empty-dark {
            background-color: $gray-9d;
        }
    }
