.machine-installing-quarters {
    position: relative;
    width: to-rem(90);
    height: to-rem(17);
    color: $white;
    font-weight: $font-weight-bold;
    background-color: $gray-30;
    border-radius: 10px;
}

    .machine-installing-quarters__progress {
        height: 100%;
        background: $lightblue;
        border-radius: 10px;
    }

    .machine-installing-quarters__text {
        @include font-size(10px);

        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: to-rem(8);
        bottom: 0;
    }

    .machine-installing-quarters__clock {
        position: absolute;
        top: to-rem(-2);
        left: to-rem(-4);
        width: to-rem(22);
        height: to-rem(22);
    }
