.machine-quarter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: to-rem(60);
    height: to-rem(62);
}

.machine-quarter--full-automatic {
    padding: to-rem(12) to-rem(16) to-rem(26);
    width: to-rem(110);
    height: to-rem(94);
    background: $black;
    border-radius: $border-radius-xs;
    box-sizing: border-box;
}

.machine-quarter__full-automatic {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: to-rem(78);
    height: to-rem(56);
    background-size: 200% 200%;
    background-image: repeating-linear-gradient(
        to right,
        $gray-97,
        $gray-97 to-rem(2),
        $gray-b4 to-rem(2),
        $gray-b4 to-rem(20)
    );

    border: 4px solid $white;
    border-radius: $border-radius-xs;
    box-sizing: border-box;

    &.producing {
        animation: slide 2s linear infinite;
    }
}

.machine-quarter__arms {
    position: absolute;
    top: to-rem(-2);
    z-index: 1;

    &.producing {
        animation: bounce-arms ease 0.5s infinite;
    }
}

.machine-quarter__text {
    @include font-size(16px);

    color: $gray-30;
    font-weight: $font-weight-black;
    line-height: 1;
    text-transform: uppercase;
    -moz-text-fill-color: $black;
    -webkit-text-fill-color: $black;
    -moz-text-stroke-color: $gray-c4;
    -webkit-text-stroke-color: $gray-c4;
    -moz-text-stroke-width: 0.5px;
    -webkit-text-stroke-width: 0.5px;
}

.machine-quarter__text--active {
    color: $red-ff;
    -moz-text-fill-color: $red-ff;
    -webkit-text-fill-color: $red-ff;
    -moz-text-stroke-color: $white;
    -webkit-text-stroke-color: $white;
    -moz-text-stroke-width: 0.5px;
    -webkit-text-stroke-width: 0.5px;
}

.machine-quarter__product {
    position: absolute;
    top: 0.8rem;
}
