.liquidity-calculation {}

    .liquidity-calculation__input {
        @include font-size(12px);

        flex: 1;
        padding-right: to-rem(3);
        width: 100%;
        font-weight: $font-weight-bold;
        text-align: center;
        background: transparent;
        border: 0;
        overflow-x: scroll;
        overflow-y: hidden;
        box-sizing: border-box;
    }

    .liquidity-calculation__data {
        display: flex;
        flex-direction: column;
    }

    .liquidity-calculation__sub-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: sticky;
        top: to-rem(30);
        z-index: 1;
        width: to-rem(3670);
        height: to-rem(40);
        font-size: to-rem(12);
        font-weight: $font-weight-bold;
        background-color: $gray-ed;
        border-bottom: to-rem(1) solid $gray-ed;
    }

        .liquidity-calculation__sub-header__empty-space {
            display: flex;
            position: sticky;
            left: 0;
            height: 100%;
            background-color: $gray-ed;
            flex-basis: 9.1%;
            flex-shrink: 0;
        }
