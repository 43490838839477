.participants {}

.participants__tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;
}

.participants__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: to-rem(20);
    width: 100%;
    background-color: $gray-d4;
    border: to-rem(4) solid $white;
    border-radius: to-rem(20) 0 to-rem(20) to-rem(20);
    box-sizing: border-box;
}
