.bottom-bar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    width: 100%;
    height: to-rem(108);
    background-color: $gray-30;
    box-sizing: border-box;
}
