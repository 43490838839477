.box {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0.5rem;
    padding: 1rem 2rem;
    width: fit-content;
    min-height: to-rem(280);
    background: $gray-d4;
    border: 4px solid $white;
    border-radius: $border-radius;
    box-sizing: border-box;
}

.box--quarterly {
    padding: 1rem;
    min-width: to-rem(322);
    min-height: 0;
}

.box--factory {
    padding: 1rem;
    min-width: to-rem(310);
    min-height: to-rem(184);

    background-size: to-rem(44) to-rem(44);
    background-image: linear-gradient($gray-c4 0.1em, transparent 0.1em),
                      linear-gradient(90deg, $gray-c4 0.1em, transparent 0.1em);
}

.box--dashboard, %box--dashboard {
    min-height: 0;
    margin: to-rem(10);
    margin-top: to-rem(30);
    padding: 0;
    background: $gray-c4;
    border: 0;
}

.box--dashboard-light {
    @extend %box--dashboard;

    padding: to-rem(5);
    background: $white;
}

.box--focus {
    z-index: $focus-z-index;
}

.box--side-effect {
    z-index: $side-actions-z-index;
}

.box__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

    .box__loading {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

.box__container {
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
}
