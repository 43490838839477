$loan-button-hover: darken($lightblue, 5%) !default;

.loan {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-right: to-rem(5);
    width: to-rem(144);
    height: to-rem(83);
    font-size: 0;
    background: $gray-30;
    border-radius: to-rem(10);
    box-shadow: inset 0 to-rem(4) to-rem(10) $box-shadow-coin-container;
}

.loan--static {
    width: to-rem(90);
}

    .loan__header {
        display: inline-block;
        position: absolute;
        top: 0;
        z-index: 3;
        margin: 0 auto;
        width: 100%;
        height: to-rem(20);
        color: $white;
        font-size: to-rem(8);
        font-weight: $font-weight-bold;
        line-height: to-rem(22);
        text-align: center;
        border-radius: to-rem(10) to-rem(10) 0 0;
    }


    .loan__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: to-rem(20);
        font-size: 0;
    }

    .loan__mu {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .loan__buttons {
        display: flex;
        justify-content: space-between;
        margin-top: -1.3rem;
        width: to-rem(98);
    }

    .loan__interest {
        @include font-size(8px);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        bottom: 0.2rem;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

        .loan__interest-text {
            display: inline-flex;
            margin: 0 to-rem(4) 0 to-rem(8);
        }

        .loan__interest-label {
            margin-right: to-rem(2);
            color: $gray-c4;
            font-weight: $font-weight-bold;
        }

        .loan__interest-percentage {
            display: inline-flex;
            color: $yellow;
            font-weight: $font-weight-bold;
        }

        .loan__interest-timelapse {
            color: $white;
            font-weight: $font-weight-bold;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

    .loan__button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0.8rem;
        width: to-rem(18);
        height: to-rem(16);
        background-color: $lightblue;
        border-radius: 5px;

        img {
            width: to-rem(11);
            height: to-rem(8);
        }

        &:hover {
            background-color: $loan-button-hover;
        }
    }

    .loan--short {
        .loan__header { background: $purple; }
    }

    .loan--long {
        .loan__header { background: $green; }
    }

    .loan--emergency {
        .loan__header { background: $red-9a; }
    }

    .loan--disabled {
        cursor: not-allowed;

        .loan__header { background: $gray-5c; }
    }
