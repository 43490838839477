.calculations-products {
    display: flex;
    justify-content: space-around;
    width: to-rem(330);
}

    .calculations-products__cells {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .calculations-products__cell {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        flex: 1;
        width: to-rem(66);
        font-weight: $font-weight-bold;
    }

    .calculations-products__cell--initial {
        span {
            @include font-size(8px);
        }
    }

    .calculations-products__values {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding-right: to-rem(4);
        width: 100%;
        height: 100%;
    }

    .calculations-products__first-value, %calculations-products__first-value {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        text-transform: uppercase;
    }

    .calculations-products__second-value {
        @extend %calculations-products__first-value;

        text-transform: none;
        border-left: to-rem(1) solid $gray-ed;
    }

