$character-size: 6.14rem;

.player-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    margin-right: auto;
    min-width: 18.4rem;
    min-height: 5rem;
}

.player-info__character {
    position: absolute;
    left: 0;
    z-index: $focus-z-index;
    width: $character-size;
    height: $character-size;
}

.player-info__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 3rem;
    width: to-rem(206);
    height: to-rem(70);
    background: $gray-5c;
    border: 3px solid $black;
    border-radius: 15px;
    box-shadow: inset 3px 1px 0 6px $gray-6d;
    box-sizing: border-box;
}

.player-info__team-name {
    @include font-size(12px);

    margin-bottom: 0.5rem;
    color: $yellow;
    font-weight: $font-weight-bold;
    line-height: 1;
}

.player-info__player-name {
    @include font-size(21px);

    color: $white;
    font-weight: $font-weight-bold;
    line-height: 1;
}
