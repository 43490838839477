.product-badge {
    display: flex;
    flex-direction: column;
    width: to-rem(136);
    border-radius: $border-radius-sm;
    box-shadow: inset 0 3px 3px $black;
    box-sizing: border-box;
}

    .product-badge__header {
        @include font-size(12px);

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: to-rem(18);
        color: $white;
        font-weight: $font-weight-bold;
        background: $gray-6d;
        border-radius: $border-radius-sm $border-radius-sm 0 0;
    }

    .product-badge__body {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        width: 100%;
        height: to-rem(42);
        background: $gray-c4;
        border-radius: 0 0 $border-radius-sm $border-radius-sm;
        box-sizing: border-box;
    }

    .product-badge__label {
        @include font-size(16px);

        margin-left: 0.5rem;
        color: $black;
        font-weight: $font-weight-bold;
    }

.product-badge--small {
    width: to-rem(74);
    height: to-rem(70);

    .product-badge__header {
        height: to-rem(22);
        background-color: $gray-30;
    }

    .product-badge__body {
        @include font-size(21px);

        height: to-rem(58);
    }
}

.product-badge--clickable {
    @extend %btn;
    z-index: 0;
    cursor: pointer;

    &:hover, &.active, &:focus:not(.btn-elevate) {
        box-shadow: 0 0 7px $lightblue;
    }

    &.disabled {
        box-shadow: none;
        cursor: not-allowed;
    }
}
