.years-in-use {
    @include font-size(10px);

    position: relative;
    width: to-rem(166);
    height: to-rem(18);
    color: $white;
    font-weight: $font-weight-bold;
    background: $gray-30;
    border-radius: $border-radius;
}

.years-in-use__progress {
    height: 100%;
    background: $lightblue;
    border-radius: $border-radius;
}

.years-in-use__text {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
